import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { filterAAFFs, getEstadosFincas, getServicesPropsGroup, listAAFFs } from "../../services";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { UsuarioContext } from "../../providers/userContext";
import {
  getAllConsultores,
  listCosultor,
  getConsultores,
} from "../../services";
import {
  ordenarNombreYApellido,
  orderArray,
  ordenarProvinciasYMunicipios,
} from "../../functions/ordenarListas";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import {
  cambiarFormatoNombre,
  municipiosIniciales,
} from "../../functions/funciones";
import SearchProgresState from "./SearchProgresState";
import { useLocation } from "react-router-dom";

const FiltrosGestion = ({
  onFilter,
  clearFilter,
  filtrosIniciales,
  ocultarAaff,
}) => {
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [aaff, setAAFF] = useState([]);

  const user = useContext(UsuarioContext);
  const [typeInicio, setTypeInicio] = useState("text");
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [typeFin, setTypeFin] = useState("text");
  const [consultor, setConsultor] = useState([]);
  const [estado, setEstado] = useState([]);
  const [sinConsul, setSinConsul] = useState(false);

  const location = useLocation();

  useEffect(() => {
    getDatas();
  }, []);

  const handleFocusInicio = () => setTypeInicio("date");
  const handleBlurInicio = () => {
    if (!busqueda.fechaAltaInicio) setTypeInicio("text");
  };
  const handleFocusFin = () => setTypeFin("date");
  const handleBlurFin = () => {
    if (!busqueda.fechaAltaFin) setTypeFin("text");
  };

  // useEffect(() => {
  //   if (user.rol !== "AAFF") {

  //     filterAAFFs({ filtros: {}, params: {}}).then(res => {
  //       setAAFF(orderArray(res.data))
  //     })
  //   }

  // }, [])

  const getDatas = async () => {
    const estados = await getEstadosFincas();
    const prefijos = await getServicesPropsGroup();
    const listado = prefijos.flatMap((prefijo, index) =>
      estados.map((estado, i) => ({
        id: index * estados.length + i + 1,
        nombre: `${prefijo.nombre} - ${estado.nombre}`
      }))
    );
    setEstado(listado);
    const consul =
      user.rol === "CONSUL"
        ? await getConsultores({ filtros: { id: user.id } })
        : [];

    if (user.rol === "CONSUL" && consul.data?.length > 0) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id,
      });

      setConsultor(ordenarNombreYApellido(consul.data));
    }
  };

  useEffect(() => {
    setListadoMunicipios(
      municipiosIniciales(provinces, municipalities, busqueda.provincia)
    );
  }, [busqueda]);

  useEffect(() => {
    cargarUser();
  }, []);

  const cargarUser = () => {
    if (user.rol === "CONSUL" && sinConsul == false) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id,
      });
    }
    localStorage.setItem("hasReloaded", "false");
  };

  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {
      clearAllFilters();
      cargarUser();
      localStorage.setItem("hasReloaded", "false");
    }
  }, [location]);

  const clearAllFilters = () => {
    setSinConsul(false);
    setBusqueda({});
    setTypeInicio("text");
    setTypeFin("text");
    clearFilter();
    cargarUser();
  };

  const seleccionarMunicipios = (e) => {
    let codProvincia = "";
    let municipios = [];
    provinces.map((p) => {
      if (p.name === e.target.value) {
        codProvincia = p.code;
      }
    });
    if (e.target.name === "provincia" || e.target.value === "") {
      setBusqueda({
        ...busqueda,
        [e.target.name]: e.target.value,
        municipio: "",
      });
    } else {
      setBusqueda({
        ...busqueda,
        [e.target.name]: e.target.value,
      });
    }
    municipalities.map((m) => {
      if (m.provCode === codProvincia) {
        municipios.push({
          ...m,
          name: cambiarFormatoNombre(m.name),
          value: m.name,
        });
      }
    });
    setListadoMunicipios(municipios);
  };

  const handleAsynchronousChange = (name, selectedValue) => {
    setBusqueda({
      ...busqueda,
      [name]: selectedValue?.id,
    });
  };

  const busquedaInput = (name, valorBusqueda) => {
    if (
      name === "aaff_id" &&
      user.rol !== "AAFF" &&
      (busqueda.aaff_id !== undefined || valorBusqueda)
    ) {
      listAAFFs({
        filtros: {
          razonSocial: valorBusqueda,
        },
        params: {},
      }).then((res) => {
        setAAFF(orderArray(res.data));
      });
    }

    if (
      name === "consul_id" &&
      user.rol !== "AAFF" &&
      (busqueda.consul_id !== undefined || valorBusqueda)
    ) {
      listCosultor({
        filtros: {
          nombreA: valorBusqueda,
        },
        params: {},
      }).then((res) => {
        setConsultor(ordenarNombreYApellido(res.data));
      });
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;

    //Eliminar estadoLopd si se selecciona un estadoCae
    if (busqueda.estadoLopd) {
      delete busqueda.estadoLopd;
    }
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }

    if (busqueda.consul_id == undefined) {
      setSinConsul(true);
    }
    //Si estado cae es mayor a 5, se cambia a estado lopd    
    if (busqueda.estadoCae >= 5) {
      delete busqueda.estadoLopd;
      // delete busqueda.estadoCae;
      busqueda.estadoLopd = busqueda.estadoCae - 4;
      delete busqueda.estadoCae;
    } else {
      delete busqueda.estadoLopd;
    }

    onFilter(busqueda);

  };

  return (
    <>
      <div className="buscadorFiltros">
        <div style={{ width: "100%" }}>
          <Box mx={1} display={"block"} flexDirection={"row"} gap={2}>
            {!ocultarAaff && (
              <TextField
                id="nif-input"
                label="NIF"
                variant="outlined"
                name="nif"
                className="filtros altura"
                value={busqueda.nif || ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                inputProps={{
                  "aria-label": "controlled",
                }}
              />
            )}
            <TextField
              id="razonSocial-input"
              label="Razón Social"
              variant="outlined"
              name="razonSocial"
              className="filtros altura"
              value={busqueda.razonSocial || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            <TextField
              id="codigoPostal-input"
              label="Código postal"
              variant="outlined"
              name="codigoPostal"
              className="filtros altura"
              value={busqueda.codigoPostal || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            {user.rol !== "AAFF" && (
              <>
                <TextField
                  type={typeInicio}
                  id="fechaAltaInicio-input"
                  label="Fecha alta (Desde)"
                  variant="outlined"
                  name="fechaAltaInicio"
                  className="filtros altura"
                  value={busqueda.fechaAltaInicio || ""}
                  onChange={handleChange}
                  onFocus={handleFocusInicio}
                  onBlur={handleBlurInicio}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                  inputProps={{
                    "aria-label": "controlled",
                  }}
                />
                <TextField
                  type={typeFin}
                  id="fechaAltaFin-input"
                  label="Fecha alta (Hasta)"
                  variant="outlined"
                  name="fechaAltaFin"
                  className="filtros altura"
                  value={busqueda.fechaAltaFin || ""}
                  onChange={handleChange}
                  onFocus={handleFocusFin}
                  onBlur={handleBlurFin}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                  inputProps={{
                    "aria-label": "controlled",
                  }}
                />
              </>
            )}
            <FormControl variant="outlined" className="filtros-select">
              <InputLabel id="provincia-label">Provincia</InputLabel>
              <Select
                labelId="comunidad-label"
                id="province-select"
                label="Provincia"
                name="provincia"
                className="filtros"
                value={busqueda.provincia || ""}
                onChange={seleccionarMunicipios}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{ height: 40 }}></MenuItem>
                {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.name}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="filtros-select">
              <InputLabel id="municipio-label">Municipios</InputLabel>
              <Select
                labelId="municipio-label"
                id="municipio-select"
                label="Municipio"
                name="municipio"
                className="filtros"
                value={busqueda?.municipio || ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{ height: 40 }}></MenuItem>
                {listadoMunicipios
                  .sort(ordenarProvinciasYMunicipios)
                  ?.map((e, i) => {
                    return (
                      <MenuItem key={i} value={e.name}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {user.rol !== "AAFF" && user.rol !== "COMM" && (
              <>
                <FormControl variant="outlined" className="filtros-select">
                  <SearchProgresState
                    labelId="aaff-label"
                    id="aaff-select"
                    label="Administrador de fincas"
                    name="aaff_id"
                    className="filtros"
                    tipo="razonSocial"
                    array={aaff}
                    onOptionSelected={handleAsynchronousChange}
                    busquedaInput={busquedaInput}
                    valueOnLoad={
                      aaff.find((c) => c.id === busqueda.aaff_id) || null
                    }
                  />
                </FormControl>

                <FormControl variant="outlined" className="filtros-select">
                  <SearchProgresState
                    labelId="consultor-label"
                    id="consultor-select"
                    label="Consultor"
                    name="consul_id"
                    className="filtros"
                    tipo="nombre"
                    array={consultor}
                    onOptionSelected={handleAsynchronousChange}
                    busquedaInput={busquedaInput}
                    valueOnLoad={consultor.find(
                      (c) => c.id === busqueda.consul_id
                    )}
                  />
                </FormControl>
              </>
            )}
            <FormControl variant="outlined" className="filtros-select">
              <InputLabel id="estadoCaeLopd-label">Estado CAE/LOPD</InputLabel>
              <Select
                labelId="comunidad-label"
                id="estadoCaeLopd-select"
                label="estadoCaeLopd"
                name="estadoCae"
                className="filtros"
                value={
                  busqueda.estadoLopd
                    ? busqueda.estadoLopd + 4
                    : busqueda.estadoCae || ""
                }
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{ height: 40 }}></MenuItem>
                {estado.map((e, i) => {                    
                    return (
                      <MenuItem key={i} value={e.id}>
                        {e.nombre}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {/* <FormControl variant="outlined" className='filtros-select'>
              <InputLabel id="estadoLopd-label">Estado LOPD</InputLabel>
              <Select
                labelId="comunidad-label"
                id="estadoLopd-select"
                label="estadoLopd"
                name="estadoLopd"
                className="filtros"
                value={busqueda.estadoLopd || ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{ height: 40 }}></MenuItem>
                {estado?.map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.id}>
                      {e.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
          </Box>
        </div>
        <div>
          <Button className="btn-buscador" onClick={clearAllFilters}>
            <ImCross />
          </Button>
        </div>
        <div>
          <Button className="btn-buscador" onClick={() => getBusqueda()}>
            <BsSearch />
          </Button>
        </div>
      </div>
      <style jsx="true">
        {`
          .btn-buscador {
            padding: 5px 10px;
            color: #ffffff;
            background-color: #92004d;
            border-radius: 4px;
            font-weight: bold;
            width: -moz-fit-content;
            width: fit-content;
            align-self: flex-end;
            border: none;
            min-width: 40px;
            height: 40px;
            margin: 5px 10px 5px;
          }
          .btn-buscador:hover {
            color: black;
            background-color: #dee2e6;
          }
        `}
      </style>
    </>
  );
};

export default withRouter(FiltrosGestion);
