import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { DragNDrop } from "../../../components";
import { createVisitSheet, getFinca,  getStatement } from "../../../services";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { comprimirImagen, formatoFechas } from "../../../functions/funciones";
import { toast } from "react-toastify";
import CustomImageList from "../../../components/ImageList/CustomImageList";
import { verificarFecha } from "../../../functions/validator/formValidator";



const ColorButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#92004D",
  "&:hover": {
    backgroundColor: "#92004D",
  },
}));

const TIPOS_EDIFICIO = [
  {
    value: "edificio",
    label: "EDIFICIO",
  },
  {
    value: "garage",
    label: "GARAGE",
  },
];

const SubQuestion = ({ question, checkBind, onChange, noTakes }) => {
  const [noTakesTrue, setNoTakesTrue] = useState(false);
  

 
  return (
    <Box className={`lae-flex lae-flex-column`} sx={{ gap: 0 }}>
      <Box

      >
        <Box sx={{ flex: 5 }}>
          <Typography>{question}</Typography>
        </Box>

        {!noTakesTrue && (
          <Box
            className="lae-flex lae-flex-row "
            sx={{ gap: 1, flex: 5 }}
          >
            <RadioGroup
              aria-labelledby="controlled"

              value={checkBind}
              sx={{ flexDirection: "row" }}
              onChange={(e) => {
                const checked = { target: { checked: e.target.value } };


                onChange(checked);
              }}
            >
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    sx={{
                      color: "#92004D",
                      "&.Mui-checked": {
                        color: "#92004D",
                      },
                    }}
                  />
                }
                label="No"
              />
              <FormControlLabel
                value="si"
                control={
                  <Radio
                    sx={{
                      color: "#92004D",
                      "&.Mui-checked": {
                        color: "#92004D",
                      },
                    }}
                  />
                }
                label="Si"
              />
              {noTakes && (
                <FormControlLabel
                  value="np"
                  control={
                    <Radio
                      sx={{
                        color: "#92004D",
                        "&.Mui-checked": {
                          color: "#92004D",
                        },
                      }}
                    />
                  }
                  label="No Procede"
                />
              )}
            </RadioGroup>

          </Box>
        )}
      </Box>
    </Box>
  );
};

function Comunidad(props) {
  const [formData, setFormData] = useState({
    aaff_id: "",
    work_id: "",
    edificio: "edificio",
    IdComunidad: "",
    comunidad: "",
    IdTecnicoPRL: "",
    tecnicoPRL: "",
    fechaVisita: formatoFechas(new Date()),
    numeroPlantas: "",
    comentarios: "",
    questions: {},
  });


  let esPregunta15Si =false;
  const [formErrors, setFormErrors] = useState({});
  const user = useContext(UsuarioContext)

  const [imagenComunidad, setImagenComunidad] = useState(null);
  const [imagenUrl, setImagenUrl] = useState(null);

  const [imagenesComunidad, setImagenesComunidad] = useState([]);
  const [imagenesUrl, setImagenesUrl] = useState([]);
  const [finca, setFinca] = useState({});
  const [recargar, setRecargar] = useState({});
  const [imagesToDelete, setImagesToDelete] = useState([]);

  const [statement, setStatement] = useState([]);

  useEffect(() => {
    fetchData()
  }, [recargar])



  const fetchData = async () => {

    const resFinca = await getFinca(props.params.nif)
    // const resSale = await getSale(props.params.id)
    const resStatement = await getStatement()
    
    setStatement(resStatement)
    setFinca(resFinca)
    let nombre = ''
    let work_id = null
    if (props.params.idCentroTrabajo) {
      nombre = resFinca.workcenters.find(ct => ct.id === Number(props.params.idCentroTrabajo))?.razonSocial
      work_id = parseInt(props.params.idCentroTrabajo)
    } else {
      nombre = resFinca.razonSocial
      work_id = null
    }

    const initialQuestions = resStatement.reduce((acc, s, index) => {
      acc[s.id] = {
        statements_id: s.id,
        respuesta: null,
        subquestions: index === 0 ? s.subpreguntas.reduce((subAcc, sub) => {
          subAcc[sub.id] = { statements_id: sub.id, respuesta: null };
          return subAcc;
        }, {}) : {}
      };
      return acc;
    }, {});



  
    setFormData((prev) => ({
      ...prev,
      sale_id: Number(props.params.id),
      work_id: work_id,
      aaff_id: resFinca.aaff.id,
      IdComunidad: resFinca.id,
      comunidad: nombre,
      user_id: user.user_id,
      IdTecnicoPRL: user.id,
      tecnicoPRL: user.nombre + " " + user.apellidos,
      saleDate: formatoFechas(new Date()),
      questions: initialQuestions
    }))
  }
 
  const handleUploadImg = async (files) => {
   
    const firstImage = files[0];

    // Crear una URL de objeto para la imagen seleccionada
    // const imageUrl = URL.createObjectURL(firstImage);
    const imageUrl = await comprimirImagen(firstImage, 14).then((res) => {
      return URL.createObjectURL(res)
    })
    // Actualizar el estado con la URL de la imagen
    setImagenComunidad(firstImage);
    setImagenUrl(imageUrl);
  }
  const handleUploadImgs = async (files) => {
    const nuevasImagenes = [];
    await Promise.all(
      files.map((file) => {
        return comprimirImagen(file, 14).then((res) => {
          nuevasImagenes.push(res);
          return URL.createObjectURL(res);
        });
      })
    )
    setImagenesComunidad((prevImagenes) => [...prevImagenes, ...files]);

    const ultimoId = imagenesUrl.length > 0 ? imagenesUrl[imagenesUrl.length - 1].indice + 1 : 0;

    // setImagenesUrl((prevUrls) => [...prevUrls, ...nuevasImagenes.map((img) => URL.createObjectURL(img))]);
    setImagenesUrl((prevUrls) => [...prevUrls, ...nuevasImagenes.map((img, i) => { return { indice: ultimoId + i, id: ultimoId + i, img: URL.createObjectURL(img) } })]);


    // files.map((file) => {
    //   comprimirImagen(file, 14).then(res =>{
    //     setImagenesComunidad([...imagenesComunidad, res])
    //     setImagenesUrl([...imagenesUrl, URL.createObjectURL(res)])
    //   })
    // });
  }
  const validateForm = () => {
    const requiredFields = ['numeroPlantas', 'fechaVisita', 'questions'];
    const newErrors = {};

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = "Este campo es obligatorio";
      }
    });

    const fechaCorrecta = verificarFecha(formData.fechaVisita);
    if (fechaCorrecta === false) {
      toast.error("la fecha selecionada no puede ser superior a la actual");
      newErrors.fechaVisita = "La fecha seleccionada no puede ser superior a la actual";
    }

    if (!imagenComunidad) {
      newErrors.imagenPrincipal = "Falta la imagen principal";
    }

    let algunaPreguntaPrincipalSinRespuesta = false;
    let algunaSubpreguntaSinRespuesta = false;

    Object.keys(formData.questions || {}).forEach(questionId => {
      const question = formData.questions[questionId];
      if (!question.respuesta) {

        algunaPreguntaPrincipalSinRespuesta = true;
        newErrors.question = "faltan preguntas"
      }

      if (question.respuesta === "si") {
        const subquestions = formData.questions[questionId].subquestions || {};
        Object.keys(subquestions).forEach(subquestionId => {
          const subquestion = subquestions[subquestionId];
          if (!subquestion.respuesta) {
            algunaSubpreguntaSinRespuesta = true;
            newErrors.subquestion = "faltan subpreguntas"
          }
        });
      }
    });

    if (algunaPreguntaPrincipalSinRespuesta || algunaSubpreguntaSinRespuesta) {
      toast.error("Falta responder alguna pregunta.");
    }

    console.log(newErrors)
    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      toast.error("Por favor complete los campos obligatorios.");
      return;
    }
    let toastInfo = toast.info("Guardando datos", { autoClose: false });
    const requiredFields = ['numeroPlantas', 'fechaVisita'];
    const emptyFields = requiredFields.filter(key => !formData[key]);
    let fechaCorrecta = verificarFecha(formData.fechaVisita);
   if (emptyFields.length > 0) {
      toast.update(toastInfo, {
        render: "Rellena todos los campos",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    }
    if (fechaCorrecta === false) {
      toast.error("la fecha selecionada no puede ser superior a la actual");
    }
    else {

      const datos = { ...formData }
      const res = await createVisitSheet(datos, imagenesComunidad, imagenComunidad, props.params.nif)
      if (res.status === 201) {
        window.history.back();
        toast.update(toastInfo, {
          render: "Datos guardados correctamente",
          type: toast.TYPE.SUCCESS,
          autoClose: true
        });
      }
      else if (res.status === 403) {
        toast.update(toastInfo, {
          render: res.data.error[0],
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      }
      else {
        toast.update(toastInfo, {
          render: "Error al guardar los datos",
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      }
    }
  }


  const addImagesToDelete = (image) => {
    const imgElement = document.querySelector(`.img-${image.indice}`);
    if (imgElement) {
      const nuevosDatos = imagenesUrl.filter(item => item.indice !== image.indice);
      const l = [...imagenesComunidad]
      l.splice(image.indice, 1)
      // const nuevosimagenesComunidad = l.splice(image.indice, 1);
      // const a = [...nuevosimagenesComunidad]
      setImagenesComunidad(l)
      setImagenesUrl(nuevosDatos)
      setTimeout(() => {

        imgElement.remove();
        setImagesToDelete((prevs) => [...prevs, { indice: image.indice, id: image.id, path: image.img }]);
      }, 3000)
    }

  };

  return (
    <>
      <Box className="pageHeader" sx={{ backgroundColor: "#f6f7fb" }}>
        <Typography variant="h4" color="#212529" fontWeight={"bold"}>
          Hoja de Visita
        </Typography>
      </Box>
      <Box className="pageContent comunidad-container centered" sx={{ p: 3 }}>
        <Typography
          variant="h6"
          color="#212529"
          fontWeight={"bold"}
          sx={{ mb: 4 }}
        >
          Datos de la Comunidad
        </Typography>

        <Box className="lae-flex lae-flex-row lae-flex-between" sx={{ mb: 2 }}>
          <Box
            className="lae-flex lae-flex-column"
            sx={{
              flex: 1,
            }}
          >
            <Box className="lae-flex lae-flex-row">
              <TextField
                id="outlined-select-currency"
                select
                label="Tipo de edificio"
                defaultValue="edificio"
                /* helperText="Seleccione el tipo de edificio" */
                fullWidth
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    edificio: e.target.value,
                  }))
                }
              >
                {TIPOS_EDIFICIO.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-select-community"
                label={props.params.idCentroTrabajo ? "Centro de trabajo" : "Comunidad"}
                fullWidth
                value={formData.comunidad}
                disabled
              // defaultValue={formData.comunidad}
              ></TextField>
            </Box>
            <Box className="lae-flex lae-flex-row">
              <TextField
                type="number"
                id="outlined-select-plantas-number"
                label="Nº de plantas"
                required
                fullWidth
                value={formData.numeroPlantas}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    numeroPlantas: e.target.value,
                  }))
                }
                error={!!formErrors.numeroPlantas}
                helperText={formErrors.numeroPlantas}
              ></TextField>
              <TextField
                id="outlined-select-tecnico-prl"
                label="Técnico PRL"
                fullWidth
                value={formData.tecnicoPRL}
                disabled
                style={{ webkitTextFillColor: 'rgba(0, 0, 0, 0.8)' }}
              // defaultValue={formData.comunidad}
              ></TextField>
            </Box>

            <TextField
              id="outlined-select-date"
              type="date"
              label="Fecha Visita"
              variant="outlined"
              value={formData.fechaVisita}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  fechaVisita: e.target.value,
                }))
              }
              inputProps={{
                "aria-label": "controlled",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </Box>
          <Box
            className="lae-flex lae-flex-column pos-relative"
            sx={{
              flex: 1,
            }}
          >
            <DragNDrop
              onUpload={(files) => { handleUploadImg(files) }}
              count={1}
              formats={["jpeg", "jpg", "png", "svg"]}
              label={
                <Typography variant="caption">Imagen Comunidad</Typography>
              }
              title='"Suelta o elige un archivo..."'
              openDialogOnClick
            />
            {imagenUrl !== null &&
              <img src={imagenUrl} alt={`Imagen principal`} style={{ maxWidth: '100%', height: '350px', objectFit: 'cover' }} />
            }

            <DragNDrop
              onUpload={(files) => handleUploadImgs(files)}
              count={10}
              formats={["jpeg", "jpg", "png", "svg"]}
              label={
                <Typography variant="caption">Imagenes Comunidad</Typography>
              }
              title='"Suelta o elige un archivo..."'
              openDialogOnClick
            />
            <Box>
              <CustomImageList imgs={imagenesUrl} addImagesToDelete={addImagesToDelete} />
              {/* {imagenesUrl.map((imageUrl, index) => (
                <img key={index} src={imageUrl} alt={`Imagen ${index + 1}`} style={{ maxWidth: '100%', width:'150px' }} />
              ))} */}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
            id="outlined-multiline-static"
            label="Comentarios adicionales"
            multiline
            rows={4}
            // placeholder="Escribe algo..."
            defaultValue=""
            sx={{ width: "100%", alignSelf: "center", my: 2 }}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                comentarios: e.target.value,
              }))
            }
          />
          <Typography
            variant="h6"
            color="#212529"
            fontWeight={"bold"}
            sx={{ mb: 4 }}
          >
            Riesgos del edificio generales
          </Typography>
          {statement.map(s => {
            return (
              <Box
                className="lae-flex lae-flex-row lae-flex-betweeen"
                key={s.id}
              >
                <Box
                  // className="lae-flex lae-flex-row"
                  sx={{ flex: 1, alignItems: "start" }}
                >
                  <SubQuestion
                    key={s.id}
                    question={`${s.codigo}.- ${s.pregunta}`}
                    checkBind={formData?.questions[s.id]?.respuesta}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        questions: {
                          ...prev?.questions,
                          [s.id]: {
                            ...prev?.questions?.[s.id],
                            statements_id: s.id,
                            respuesta: e.target.checked,
                            subquestions: e.target.checked === "si" ? s.subpreguntas.reduce((subAcc, subPreg) => {
                              subAcc[subPreg.id] = { statements_id: subPreg.id, respuesta: undefined };
                              return subAcc;
                            }, {}) : {}
                          }
                        }
                        // p1_0: e.target.checked === "true" ? "si" : "no",
                      }))
                    }
                    noTakes={s.noProcede ? true : false}

                  />
                </Box>
                {(formData?.questions[s.id]?.respuesta === 'si' || s.codigo === 1) &&
                  <Box
                    className="lae-flex lae-flex-column"
                    sx={{
                      flex: 1,
                      p: 2,
                      boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "rgb(229 216 216 / 10%)",
                      borderRadius: "10px",
                    }}
                  >
                   {s.subpreguntas.map(p => {
                     //console.log("formData?.questions[s.id]?.subquestions[p.id]?",formData?.questions[s.id]?.subquestions[p.id]);
                      if(formData?.questions[s.id]?.subquestions[p.id]?.statements_id==15 && formData?.questions[s.id]?.subquestions[p.id]?.respuesta == "si"){
                        esPregunta15Si = true;
                      }
                      if(formData?.questions[s.id]?.subquestions[p.id]?.statements_id==15 && formData?.questions[s.id]?.subquestions[p.id]?.respuesta == "no"){
                        esPregunta15Si = false;
                      }
                      //console.log("esPregunta15Si",esPregunta15Si);

                      
                      if (esPregunta15Si && (formData?.questions[s.id]?.subquestions[p.id]?.statements_id==16 || formData?.questions[s.id]?.subquestions[p.id]?.statements_id==17 || formData?.questions[s.id]?.subquestions[p.id]?.statements_id==18)) {
                        formData.questions[s.id].subquestions[p.id].respuesta = "null"
                        return null;
                      }

                      return (

                        <SubQuestion
                          question={`${p.codigo}.${p.subcodigo}.- ${p.pregunta}`}
                          checkBind={esPregunta15Si && (formData?.questions[s.id]?.subquestions[p.id]?.statements_id==16 || formData?.questions[s.id]?.subquestions[p.id]?.statements_id==17 || formData?.questions[s.id]?.subquestions[p.id]?.statements_id==18) ?'no': formData?.questions[s.id]?.subquestions[p.id]?.respuesta}
                          noTakes={p.noProcede ? true : false}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              ...prev,
                              questions: {
                                ...prev?.questions,
                                [s.id]: {
                                  ...prev?.questions?.[s.id],
                                  subquestions: {
                                    ...prev?.questions?.[s.id]?.subquestions,
                                    [p.id]: {
                                      ...prev?.questions?.[s.id]?.subquestions?.[p.id],
                                      statements_id: p.id,
                                      respuesta: e.target.checked,
                                    }
                                   
                                    
                                  }
                                }
                              }
                            }))
                          }
                        />
                      )
                    })}
                  </Box>
                }
              </Box>

            )
          })}

          <ColorButton sx={{ alignSelf: "center", width: "80%" }}
            onClick={() => handleSubmit()}
          >
            Guardar y Enviar
          </ColorButton>
        </Box>
      </Box>
      <style jsx>{`
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
          -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
        }
      `}</style>
    </>
  );
}

export default withRouter(Comunidad);
