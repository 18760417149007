import axios from "axios";

const urlPath = "serviceProp";

export const getServices = async () => {
    try {
        const response = await axios.get(`${urlPath}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
export const getServicesPropsGroup = async () => { 
    try {
        const response = await axios.get(`${urlPath}/groups`);
        return response.data;
    } catch (error) {
        console.log(error);
        
    }
}