import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import FiltrosComunidades from '../../../components/filtros/filtrosComunidades';
import BreadCrumb from '../../../components/navbar/Breadcrumb';
import { CustomSwitch } from "../../../components/Buttons/Switch";
import { DataTable } from "../../../components";
import { Box, Select } from "@mui/material";
import { getEstadosFincas, getListFincas, UpdateSale } from "../../../services";
import { diferenciaMesesCaducidad, formatoFechasEs } from '../../../functions/funciones';
import { cambiarFinca } from "../../../services";
import { UsuarioContext } from "../../../providers/userContext";

import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';



const GestionComunidades = () => {

  const user = useContext(UsuarioContext)
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [estados, setEstados] = useState([]);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });


  const [primeraCarga, setPrimeraCarga] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    if (user.rol === "CONSUL" && localStorage.getItem("hasReloaded") === "true") {
      setBusqueda({
        consul_id: user.id 
      });
    }
    else if(user.rol === "CONSUL"){
      setBusqueda({
        consul_id: user.id 
      });
    }
    else{
      setBusqueda({});
    }
    
    setClearFilterActivo(true);
  }
  
  const fetchData = async ({ offset, limit, filtros = busqueda,order,orderBy  } = {}) => {

    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order = order || filtroOrder.orden || 'ASC';


    if (primeraCarga) {
      if (filtros.consul_id === undefined && user.rol === 'CONSUL') {
        filtros.consul_id = user.id
      } else if (filtros.consul_id === "0") {
        delete filtros.consul_id
      }
      setPrimeraCarga(false);
    }

    
    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros ,order, orderBy);


    const payload = {
      filtros,
      params: { page: offset, limit: limit ,order, orderBy},
    };

    try {
      setLoading(true);
      const responseEstados = await getEstadosFincas();
      setEstados(responseEstados)
      // console.log(responseEstados);
      
      const response = await getListFincas(payload);
      const { meta, data } = response;
      // console.log(data)
      setData({
        meta,
        rows: formatData(data, meta),
      });
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data, meta) => {
    const formattedData = []
     data.map((row, i) => {
      // const diferenciaMeses = diferenciaMesesCaducidad(row.fechaRenovacion, row.createdAt)
      const sale_cae = row?.sales?.find(s => [1, 3, 6, 7].includes(s.servp_id)) || null;
      const sale_lopd = row?.sales?.find(s => [2, 4, 5].includes(s.servp_id)) || null;
      formattedData.push({
        i: (meta.current_page - 1) * meta.per_page + (i+1),
        aaff_nif: row.aaff.nif,
        razonSocial: row.razonSocial,
        nif: row.nif,
        codigoPostal: row.codigoPostal,
        municipio: row.municipio,
        provincia: row.provincia,
        proveedores: row.proveedores || row.proveedores === null ? row.suppliers.length : "No tiene",
        isWorkcenter: false,
        activo: row.activo === null ? true : row.activo,
        estado: row.estado_id === null ? 1 : row.estado_id,
        // caducada: diferenciaMeses > 13 ? true : false
        saleCae_id: sale_cae?.id || null,
        saleLopd_id: sale_lopd?.id || null,
        estadoCae: sale_cae ? sale_cae.estado_id === null ? 1 : sale_cae.estado_id : "No contratada",
        estadoLopd: sale_lopd ? sale_lopd.estado_id === null ? 1 : sale_lopd.estado_id : "No contratada",
      })
      if (row.workcenters?.length > 0) {
        row.workcenters?.forEach((wc) => {
            formattedData.push({
              i: "",
              aaff_nif: "",
              razonSocial: wc.razonSocial,
              nif: "",
              codigoPostal: "",
              municipio: "",
              provincia: "",
              proveedores: "",
              isWorkcenter: true,
              activo: "",
              estadoCae: "",
              estadoLopd: "",
              saleCae_id: "",
              saleLopd_id: "",
            });
        });
      }
    });
    return formattedData;
  };

  // const onClick = (nif) => {
  //   const actualizarEstado = data.rows.map((row) => {

  //     if (row.nif === nif) {
  //       cambiarFinca(nif, {
  //         activo: !row.activo
  //       })
  //       return { ...row, activo: !row.activo };

  //     }
  //     return row;
  //   });
  //   setData({ ...data, rows: actualizarEstado });
  // };

  const handleChangeEstado = (evt, id, serv) => {   
    console.log(evt.target.value, id, serv)    
    const valor = parseInt(evt.target.value)
    const actualizarEstado = data.rows.map((row) => {
      const sale_id = serv === 'cae' ? row.saleCae_id : row.saleLopd_id
      if (sale_id === id) {
        console.log(serv, sale_id)
        UpdateSale(id, {
          estado_id: valor
        })
        const nuevosDatos = serv === "cae" ? {estadoCae:valor} : {estadoLopd:valor}
        return { ...row, ...nuevosDatos };
      }
      return row;
    });
    console.log(actualizarEstado)
    setData({ ...data, rows: actualizarEstado });
  };

  const ComunidadesRows = [
    {
      id: "i",
      numeric: true,
      disablePadding: false,
      label: "",
    },
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
        <Link to={`/aaff/${row.aaff_nif}/comunidades/${row.nif}`}>
          {data}
        </Link>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "proveedores",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDORES",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <Link to={`/aaff/${row.aaff_nif}/comunidades/${row.nif}/proveedores`}>
          {data}
        </Link>
      )
    },
    {
      id: "estadoCae",
      numeric: false,
      disablePadding: false,
      label: "ESTADO CAE",
      overrideFunc: (data, row) => (
        !row.isWorkcenter ?
          data !== "No contratada" ?
            <div className="activoSelect">
              <Select 
                  label="EstadoCae"
                  native 
                  name="estadoCae" 
                  id="estadoCae" 
                  style={{ width: 'auto !important', textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data === 1 || null ? "#3eae3e" : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }} 
                  onChange={(event) => handleChangeEstado(event, row.saleCae_id, 'cae')} defaultValue={data}
                >
                  {estados?.map((e, i) => {
                    return (
                      <option key={i} value={e.id} style={{ color: e.id === 1 || e.id == null ? "#3eae3e"  : e.id === 2 || e.id === 3 ? '#FF8300' : e.id === 4 ? "#EE4534" : "#000" }}>
                        {e.nombre}
                      </option>
                    );
                  })}
                </Select>
            </div>
          : data
        : ""
      ),
    },
    {
      id: "estadoLopd",
      numeric: false,
      disablePadding: false,
      label: "ESTADO LOPD",
      overrideFunc: (data, row) => (
        !row.isWorkcenter ?
          data !== "No contratada" ?
            <div className="activoSelect">
              <Select 
                  label="EstadoLopd"
                  native 
                  name="estadoLopd" 
                  id="estadoLopd" 
                  style={{ width: 'auto !important', textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data === 1 || null ? "#3eae3e"  : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }} 
                  onChange={(event) => handleChangeEstado(event, row.saleLopd_id, 'lopd')} defaultValue={data}
                >

                  {estados?.map((e, i) => {                    
                    return (
                      <option key={i} value={e.id} style={{ color: e.id === 1 || e.id == null ? "#3eae3e"  : e.id === 2 || e.id === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }}>
                        {e.nombre}
                      </option>
                    );
                  })}
                </Select>
            </div>
          : data
        : ""
      ),
    },
    // {
    //   id: "activo",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "ACTIVO",
    //   noObjeto:true,
    //   overrideFunc: (data, row) => (
    //     data !== "" ?
    //       <div className="activoSelect">
    //         <Select 
    //             label="Activo"
    //             native 
    //             name="activo" 
    //             id="activo" 
    //             style={{ textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data ? "green" : 'red' }} 
    //             onChange={() => onClick(row.nif)} value={data}
    //           >
    //             <option value={true}>Sí</option>
    //             <option value={false}>No</option>
    //           </Select>
    //       </div>
    //     : ""
    //   )
    // }
  ];

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
  };

  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/comunidades", label: "Gestión comunidades" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            GESTIÓN COMUNIDADES
          </div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <FiltrosComunidades onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={ComunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay comunidades"}
                propCaducada={true}
              />
            </Box>
          </Box>
        </div>
      </div>
      <style jsx="true">{`
        #trabajadores, #activo, #estadoCae, #estadoLopd{
          padding:0 25px 0 0;
          border: none;
        }
        .activoSelect fieldset, .activoSelect select:hover{
          border-color: rgba(0, 0, 0, 0) !important;
        }        
      `}</style>
    </>
  );
};

export default withRouter(GestionComunidades);
