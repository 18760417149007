import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { getFincasAAFF } from '../../../services';
import { Box, Typography } from "@mui/material";
import { diferenciaMesesCaducidad, estadoServicio, formatoFechasEs } from "../../../functions/funciones";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable, NoVisitado } from '../../../components';
import FiltrosComunidadesTech from '../../../components/filtros/filtrosComunidadesTech';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';
import NoVisitadoAAFF from '../../../components/noVisitadoAAFF';




const ServicioCae = (props) => {

  const user = useContext(UsuarioContext);
  const [isLoading, setIsLoading] = useState(true);
  const [recargar, setRecargar] = useState(0);
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });


  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }

  // useEffect(() => {
  //   fetchData({ offset: 1, limit: limite, filtros: busqueda })
  //   //setPorcentaje(65)
  //   setRecargar(false);
  // }, [recargar, limite, busqueda]);

  const fetchData = async ({ offset, limit, filtros = busqueda, order, orderBy } = {}) => {
    setIsLoading(true);
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order = order || filtroOrder.orden || 'ASC';

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy);

    if (props.aaff?.id) {
      const payload = {
        filtros: { ...filtros, aaff_id: props.aaff.id },
        params: { page: offset, limit, order, orderBy },
      };

      try {
        const response = await getFincasAAFF(payload);
        const { meta, data } = response;
        console.log(data)
        setData({
          meta,
          rows: formatData(data),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formatData = (data) => {
    const formattedData = []
    data.map((row) => {
      // const diferenciaMeses = diferenciaMesesCaducidad(row.fechaRenovacion, row.createdAt)
      const sale_cae = row?.sales?.find(s => [1, 3, 6, 7].includes(s.servp_id)) || null;
      console.log(sale_cae ? sale_cae.estado_id === null ? 1 : sale_cae.estado_id : "No contratada")
      formattedData.push({
        id: row.id,
        finca: { ...row, properties: { nif: row.nif }, detailsCae: row.sales[0]?.detailsCae },
        aaff: row.aaff,
        sales: row.workcenters?.length > 0 ? [] : row.sales,
        razonSocial: row.razonSocial,
        nif: row.nif,
        createdAt: formatoFechasEs(row.createdAt),
        proveedores: row.proveedores || row.proveedores === null ? row.suppliers.filter(objeto => objeto.status === true).length : "No tiene",
        direccion: row.direccion,
        municipio: row.municipio,
        provincia: row.provincia,
        trabajadores: row.trabajadores === null ? false : row.trabajadores,
        codigoPostal: row.codigoPostal,
        isWorkcenter: false,
        // caducada: diferenciaMeses > 13 ? true : false,
        saleCae_id: sale_cae?.id || null,
        estadoCae: sale_cae ? sale_cae.estado_id === null ? 1 : sale_cae.estado_id : "No contratada",
        fechaUltVisita:

          row.workcenters.length === 0 && (row.visitada || row.renovadaVisitada) && row.visitSheets?.[0]?.createdAt ? (
            formatoFechasEs(row.visitSheets[0].createdAt)
          )
            :
            ((row?.sales?.[0]?.docsProps?.length === 0) || (row?.sales[0]?.docsProps?.[0]?.visitSheet_id == null && row?.sales?.[0]?.docsProps?.[0]?.ruta))?
              'Ver Informe'
              :
              row.workcenters.length > 0 ? "" : (
                <p style={{ color: "red" }}>No visitado</p>
              )
      })
      if (row.workcenters?.length > 0) {
        row.workcenters?.forEach((wc) => {
          formattedData.push({
            id: wc.id,
            finca: wc,
            aaff: row.aaff,
            sales: wc.workSales,
            razonSocial: wc.razonSocial,
            nif: "",
            createdAt: wc.createdAt ? formatoFechasEs(wc.createdAt) : "",
            direccion: "",
            municipio: "",
            provincia: "",
            trabajadores: "",
            codigoPostal: "",
            isWorkcenter: true,
            estadoCae: "",
            saleCae_id: "",
            fechaUltVisita: wc.visitSheets?.[wc.visitSheets.length - 1]?.createdAt ? (
              formatoFechasEs(wc.visitSheets?.[wc.visitSheets.length - 1]?.createdAt)
            ) :
              (
                <p style={{ color: "red" }}>No visitado</p>
              )
          });
        });
      }
    });
    return formattedData;
  };

  const comunidadesRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
        <Link to={"/aaff/" + props.params.nif + "/comunidades/" + row.nif}>
          {data}
        </Link>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "FECHA ALTA",

      overrideFunc: (data, row) => (

        <Typography style={{ textAlign: 'left' }}>
          {data}
        </Typography>

      ),
    },
    {
      id: "direccion",
      numeric: false,
      disablePadding: false,
      label: "DIRECCIÓN",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "estadoCae",
      numeric: false,
      disablePadding: false,
      label: "ESTADO CAE",
      overrideFunc: (data, row) => (
        !row.isWorkcenter ?
            <div className="activoSelect">
                <span style={{ color: data === 1 || data == null ? "#3eae3e" : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }}>
                  {data === "No contratada" ? data : estadoServicio(data)}
                </span>
            </div>
        : ""
      ),
    },
    {
      id: "trabajadores",
      numeric: false,
      disablePadding: false,
      label: "EMPLEADOS",
      overrideFunc: (data, row) => (        
        <Typography style={{ textAlign: 'left' }}>
        {data ? "SÍ" : "NO"}
      </Typography>
      ),
    },
    {
      id: "fechaUltVisita",
      numeric: false,
      disablePadding: false,
      label: "FECHA ULT VISITA",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <Typography style={{ textAlign: 'left' }}>
          {data}
        </Typography>
      )
    },
    {
      id: "noVisita",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <Typography>
          {user.rol !== 'AAFF' ?
            row?.aaff?.unRealized !== undefined && row?.aaff?.unRealized && row?.sales[0]?.detailsCae[0]?.visitSheet_id === null ?
              <NoVisitadoAAFF aaff={row?.aaff} nif={row.aaff.nif} />
              :

              row?.sales[0]?.detailsCae[0]?.unRealized && row?.sales[0]?.detailsCae[0]?.visitSheet_id === null ?
                row.isWorkcenter ?
                  <NoVisitado finca={row.finca} nif={row.id} tipo={"centroTrabajo"} />
                  :
                  // console.log(row.finca)
                  <NoVisitado finca={row.finca} nif={row.nif} tipo={"finca"} />
                : ''
            : ''}
        </Typography>
      ),
    }
  ];

  const filtrarDatos = (filtros) => {
    setBusquedaActiva(true)
    setBusqueda(filtros);
    // fetchData();
  };

  return (
    <>
      <div className="pageContent tableContainer">
        <FiltrosComunidadesTech onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
        <div className='tableWrapper'>
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={isLoading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay comunidades"}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                // propCaducada={true}
              />
            </Box>
          </Box>
        </div>
      </div>

    </>
  );
}

export default withRouter(ServicioCae);