import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import FiltrosComunidades from '../../../components/filtros/filtrosComunidades';
import BreadCrumb from '../../../components/navbar/Breadcrumb';
import { DataTable } from "../../../components";
import { Box, CircularProgress } from "@mui/material";
import { getFincasAAFF, getOneAAFF } from "../../../services";
import { cambiarFormatoNombre, diferenciaMesesCaducidad, estadoServicio, formatoFechasEs } from '../../../functions/funciones';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';



const ComunidadesRows = [
    {
        id: "comunidad_name",
        numeric: false,
        disablePadding: false,
        label: "COMUNIDAD",
        overrideFunc: (data, row) => (
            <Link to={`/aaff/${row.nifAAFF}/comunidades/${row.nif}`}>
                {data}
            </Link>
        ),
    },
    {
        id: "nif",
        numeric: false,
        disablePadding: false,
        label: "NIF",
    },
    {
        id: "fechaAlta",
        numeric: false,
        disablePadding: false,
        label: "FECHA ALTA",
    },
    {
        id: "codigoPostal",
        numeric: false,
        disablePadding: false,
        label: "CÓDIGO POSTAL",
    },
    {
        id: "municipio",
        numeric: false,
        disablePadding: false,
        label: "MUNICIPIO",
    },
    {
        id: "provincia",
        numeric: false,
        disablePadding: false,
        label: "PROVINCIA",
    },
    {
        id: "estadoCae",
        numeric: false,
        disablePadding: false,
        label: "ESTADO CAE",
        overrideFunc: (data, row) => (
          !row.isWorkcenter ?
              <div className="activoSelect">
                  <span style={{ color: data === 1 || data == null ? "#3eae3e"  : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }}>
                    {data === "No contratada" ? data : estadoServicio(data)}
                  </span>
              </div>
          : ""
        ),
    },
    {
        id: "estadoLopd",
        numeric: false,
        disablePadding: false,
        label: "ESTADO LOPD",
        overrideFunc: (data, row) => (
            !row.isWorkcenter ?
                <div className="activoSelect">
                    <span style={{ color: data === 1 || data == null ? "#3eae3e"  : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }}>
                    {data === "No contratada" ? data : estadoServicio(data)}
                    </span>
                </div>
            : ""
        ),
    },
];

const GestionComunidades = (props) => {
    const {filtrosIniciales} = useRecogerURLParamsFiltros();
    const [busqueda, setBusqueda] = useState(filtrosIniciales);
    const [aaff, setAAFF] = useState({});
    const [loading, setLoading] = useState(true);
    const [recargar, setRecargar] = useState(false);
    const [limite, setLimite] = useState(0);
    const [busquedaActiva, setBusquedaActiva] = useState(false);
    const [clearFilterActivo, setClearFilterActivo] = useState(false);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }


    useEffect(() => {
        fetchData();
        //setRecargar(false)
    }, [recargar]);

    const fetchData = async ({ offset, limit, filtros = busqueda } = {}) => {
        
        construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros);

        try {
            const dats = await getOneAAFF(props.params.nif);
            setAAFF(dats);
            setLimite(limit);
            const payload = {
                filtros: { ...filtros, aaff_id: dats.id },
                params: { page: offset, limit },
            };
            const response = await getFincasAAFF(payload);
            const { meta, data } = response;
            setData({
                meta,
                rows: formatData(data),
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setIsLoading(false); // Mover aquí para asegurarse de que se ejecute después de que los datos se hayan cargado
        }
    };

    const formatData = (data) => {
        const formattedData = [];
        console.log(data)
        data.map((row) => {
            // const diferenciaMeses = diferenciaMesesCaducidad(row.fechaRenovacion, row.createdAt)
            const sale_cae = row?.sales?.find(s => [1, 3, 6, 7].includes(s.servp_id)) || null;
            const sale_lopd = row?.sales?.find(s => [2, 4, 5].includes(s.servp_id)) || null;
            formattedData.push({
                comunidad_name: row.razonSocial,
                nif: row.nif,
                nifAAFF: row.aaff.nif,
                fechaAlta: formatoFechasEs(row.createdAt),
                codigoPostal: row.codigoPostal,
                municipio: row.municipio,
                provincia: row.provincia,
                isWorkcenter: false,
                // caducada: diferenciaMeses > 13 ? true : false
                saleCae_id: sale_cae?.id || null,
                saleLopd_id: sale_lopd?.id || null,
                estadoCae: sale_cae ? sale_cae.estado_id === null ? 1 : sale_cae.estado_id : "No contratada",
                estadoLopd: sale_lopd ? sale_lopd.estado_id === null ? 1 : sale_lopd.estado_id : "No contratada",
            });
            if (row.workcenters?.length > 0) {
                row.workcenters?.forEach((wc) => {
                    formattedData.push({
                        comunidad_name: wc.razonSocial,
                        nif: "",
                        fechaAlta: "",
                        codigoPostal: "",
                        municipio: "",
                        provincia: "",
                        isWorkcenter: true,
                        estadoCae: "",
                        estadoLopd: "",
                        saleCae_id: "",
                        saleLopd_id: "",
                    });
                });
            }
        });
        return formattedData;
    };

    const filtrarDatos = (filtros) => {
        setBusqueda(filtros);
        setBusquedaActiva(true);
        // fetchData({ offset: 1, limit: limite, filtros });
    };

    return isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ color: '#92004D' }} />
        </div>
    ) : (
        <>
        {console.log(aaff)}
            <BreadCrumb rutas={[{ path: '/aaff', label: `Administradores de fincas` }, { path: '/aaff', label: `${aaff.razonSocial}` }]} style={{ margin: '0 10px' }} />
            <div className="listPage">
                <div className="gridCard">
                    <div className="gridHead">
                        INFORMACIÓN GENERAL
                    </div>
                    <div className="gridSubhead editarSubhead">
                        <div>DATOS EMPRESA</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="datosGrid" style={{ width: '75%' }}>
                            <p>
                                <b>Nombre AAFF:</b> {aaff.razonSocial}
                            </p>
                            <p>
                                <b>NIF:</b> {aaff.nif}
                            </p>
                            <p>
                                <b>Municipio:</b> {cambiarFormatoNombre(aaff.municipio)}
                            </p>
                            <p>
                                <b>Provincia:</b> {aaff.provincia}
                            </p>
                            <p>
                                <b>Persona de contacto:</b> {aaff.nombreContacto}
                            </p>
                            <p>
                                <b>Teléfono:</b> {aaff.telefono}
                            </p>
                            <p>
                                <b>Email:</b> {aaff.user?.email}
                            </p>
                            <p>
                                <b>Técnico:</b>{" "}
                                {aaff.technician?.nombre !== undefined
                                    ? 
                                        <Link onClick={() => window.location = `mailto:${aaff.technician?.user?.email}`}>
                                            {aaff.technician?.nombre + " " + aaff.technician?.apellidos}
                                        </Link>
                                    : "-"}{" "}
                            </p>
                            {aaff?.gruposServicios?.includes(1) &&
                                <p>
                                    <b>Consultor Cae:</b>{" "}
                                    <Link onClick={() => window.location = `mailto:${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 1).user?.email}`}>
                                        {aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 1) ? `${aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 1).nombre} ${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 1).apellidos}` : ''}
                                    </Link>
                                </p>
                            }
                            {aaff?.gruposServicios?.includes(2) &&
                                <p>
                                    <b>Consultor Lopd:</b>{" "}
                                    <Link onClick={() => window.location = `mailto:${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 2).user?.email}`}>
                                        {aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 2) ? `${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 2).nombre} ${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 2).apellidos}` : ''}
                                    </Link>
                                </p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="gridSubhead editarSubhead">
                <div>LISTADO COMUNIDADES</div>
            </div>
            <div className="pageContent tableContainer">
                <FiltrosComunidades onFilter={filtrarDatos} clearFilter={clearFilters}  filtrosIniciales={busqueda} />
                <div className="tableWrapper">
                    <Box className="pageContent">
                        <Box sx={{ mb: 10 }}>
                            <DataTable
                                data={data.rows}
                                isLoading={loading}
                                headers={ComunidadesRows}
                                fetcher={fetchData}
                                currentPage={data.current_page}
                                meta={data.meta}
                                totalItems={data.total}
                                totalPages={data.totalPages}
                                nextPage={data.next_page}
                                filtros={busqueda}
                                busquedaActiva={busquedaActiva}
                                setBusquedaActiva={setBusquedaActiva}
                                clearFilterActivo={clearFilterActivo}
                                setClearFilterActivo={setClearFilterActivo}
                                mensaje={"No hay comunidades"}
                                // propCaducada={true}
                            />
                        </Box>
                    </Box>
                </div>
            </div>
        </>
    );
}

export default withRouter(GestionComunidades);
