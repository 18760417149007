import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { getConsultores, getServiciosFincas, listTecnicos, listCosultor } from '../../services';
import { UsuarioContext } from '../../providers/userContext';
import { ordenarNombreYApellido, orderArray } from '../../functions/ordenarListas';
import { ImCross } from "react-icons/im";
import { listAAFFs } from '../../services';
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,

} from "@mui/material";

import Acordeon from './acordeonFiltros';

import SearchProgresState from "./SearchProgresState";
import { useLocation } from "react-router-dom";


const FiltrosVentas = ({ onFilter, clearFilter, filtrosIniciales }) => {


  const { rol } = useContext(UsuarioContext)

  const [busqueda, setBusqueda] = useState(filtrosIniciales)
  const anoActual = new Date().getFullYear();

  const [servicios, setServicios] = useState([])
  const [aaff, setAAFF] = useState([])
  const [tecnicos, setTecnicos] = useState([])
  const [consultores, setConsultores] = useState([])
  const user = useContext(UsuarioContext)
  const [typeVentaInicio, setTypeVentaInicio] = useState('text');
  const [typeVentaFin, setTypeVentaFin] = useState('text');
  const [typeCreacionInicio, setTypeCreacionInicio] = useState('text');
  const [typeCreacionFin, setTypeCreacionFin] = useState('text');
  const [listAnos, setListAnos] = useState([]);
  const location = useLocation();
  const [sinConsul, setSinConsul] = useState(false);


  const handleFocusVentaInicio = () => setTypeVentaInicio('date');
  const handleBlurVentaInicio = () => {
    if (!busqueda.fechaVentaInicio) setTypeVentaInicio('text');
  };
  const handleFocusVentaFin = () => setTypeVentaFin('date');
  const handleBlurVentaFin = () => {
    if (!busqueda.fechaVentaFin) setTypeVentaFin('text');
  };
  const handleFocusCreacionInicio = () => setTypeCreacionInicio('date');
  const handleBlurCreacionInicio = () => {
    if (!busqueda.fechaCreacionInicio) setTypeCreacionInicio('text');
  };
  const handleFocusCreacionFin = () => setTypeCreacionFin('date');
  const handleBlurCreacionFin = () => {
    if (!busqueda.fechaCreacionFin) setTypeCreacionFin('text');
  };

  useEffect(() => {
    getDatas();
    listadoAnos();
    if (user.rol === "AAFF" && !busqueda.ano) {
      setBusqueda((prevBusqueda) => ({
        ...prevBusqueda,
        ano: anoActual
      }));
    }
  }, []);

  useEffect(() => {
    cargarUser();
  }, []);

  const cargarUser = () => {
    if (user.rol === "CONSUL" && sinConsul == false) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id
      });
    }
    localStorage.setItem("hasReloaded", "false");
  }
  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {
      clearAllFilters();
      cargarUser();
      localStorage.setItem("hasReloaded", "false");
    }

  }, [location]);

  const clearAllFilters = () => {


    setBusqueda({});
    setTypeVentaInicio('text');
    setTypeVentaFin('text');
    setTypeCreacionInicio('text');
    setTypeCreacionFin('text');
    clearFilter();
    cargarUser();

  }

  const listadoAnos = () => {

    let Anos = []
    for (let i = 2021; i <= anoActual; i++) {
      Anos.push(i)
    }
    setListAnos(Anos)
  }



  const getDatas = async () => {

    const serviciosFincas = await getServiciosFincas()
      
    setServicios(serviciosFincas)
  };


  const handleAsynchronousChange = (name, selectedValue) => {
    setBusqueda({
      ...busqueda,
      [name]: selectedValue?.id,
    });
  };

  const busquedaInput = (name, valorBusqueda) => {
    console.log(name, valorBusqueda)
    if (name === 'aaff_id' && user.rol !== 'AAFF' && (busqueda.aaff_id !== undefined || valorBusqueda)) {
      listAAFFs({
        filtros: {
          razonSocial: valorBusqueda
        }, params: {}
      }).then(res => {
        setAAFF(orderArray(res.data))
      })
    }

    if (name === 'tech_id' && (busqueda.tech_id !== undefined || valorBusqueda)) {

      listTecnicos({
        filtros: {
          nombreA: valorBusqueda
        }, params: {}
      }).then(res => {
        setTecnicos(ordenarNombreYApellido(res.data))
      })
    }


    if (name === 'consul_id' && (busqueda.consul_id !== undefined || valorBusqueda)) {


      listCosultor({
        filtros: {
          nombreA: valorBusqueda
        }, params: {}
      }).then(res => {
        setConsultores(ordenarNombreYApellido(res.data))
      })
    }

  };

  function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    if (busqueda.consul_id == undefined) {
      setSinConsul(true);
    }
    onFilter(busqueda);
  };

  return (
    <>
      <div className="buscadorFiltros">
        <div style={{ width: "100%" }}>
          <Box
            mx={1}
            display={"block"}
            flexDirection={"row"}
            gap={2}
          >
            <FormControl variant="outlined" className="filtros-select">
              <SearchProgresState
                labelId="aaff-label"
                id="aaff-select"
                label="Administrador de fincas"
                name="aaff_id"
                className="filtros altura"
                tipo="razonSocial"
                array={aaff}
                onOptionSelected={handleAsynchronousChange}
                busquedaInput={busquedaInput}
                valueOnLoad={aaff.find(c => c.id === busqueda.aaff_id) || null}
              />
            </FormControl>
            <TextField
              id="aaffNif-input"
              labelId="aaffNif-label"
              label="Nif AAFF"
              name="aaffNif"
              className="filtros altura"
              value={busqueda.aaffNif || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{ "aria-label": "controlled" }}
            />
            <FormControl variant="outlined" className='filtros-select'>
              <TextField
                id="razonSocial-input"
                label="Comunidad"
                variant="outlined"
                name='razonSocial'
                className="filtros altura"
                value={busqueda.razonSocial || ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </FormControl>
            <TextField
              id="nif-input"
              label="NIF comunidad"
              variant="outlined"
              name='nif'
              className="filtros altura"
              value={busqueda.nif || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{ "aria-label": "controlled" }}
            />
            <TextField
              id="codigoPostal-input"
              label="Código postal"
              variant="outlined"
              name='codigoPostal'
              className="filtros altura"
              value={busqueda.codigoPostal || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{ "aria-label": "controlled" }}
            />
            <FormControl variant="outlined" className='filtros-select'>
              <InputLabel id="servicio-label">Servicio</InputLabel>
              <Select
                labelId="servicio-label"
                id="servicio-select"
                label="Servicio"
                name="servp_id"
                className="filtros"
                value={busqueda.servp_id || ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{ height: 40 }}></MenuItem>
                {servicios?.map((e, i) => (
                  (rol === "TECH" && (e.id === 1 || e.id === 3)) || rol !== "TECH" ? (
                    <MenuItem key={i} value={Number(e.id) || ""}>
                      {e.nombre}
                    </MenuItem>
                  ) : null
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className='filtros-select'>
              <InputLabel id="estado-label">Estado</InputLabel>
              <Select
                labelId="estado-label"
                id="estado-select"
                label="Estado"
                name="visitada"
                className="filtros"
                value={busqueda?.visitada ?? ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{ height: 40 }}>Todos</MenuItem>
                <MenuItem value={false} style={{ height: 40 }}>Pendiente</MenuItem>
                <MenuItem value={true} style={{ height: 40 }}>Visitada</MenuItem>
              </Select>
            </FormControl>
            <TextField
              type={typeCreacionInicio}
              id="fechaCreacionInicio-input"
              label="Fecha ult. visita (Desde)"
              variant="outlined"
              name='fechaCreacionInicio'
              className="filtros altura"
              value={busqueda.fechaCreacionInicio || ""}
              onChange={handleChange}
              onFocus={handleFocusCreacionInicio}
              onBlur={handleBlurCreacionInicio}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{ "aria-label": "controlled" }}
            />
            <TextField
              type={typeCreacionFin}
              id="fechaCreacionFin-input"
              label="Fecha ult. visita (Hasta)"
              variant="outlined"
              name='fechaCreacionFin'
              className="filtros altura"
              value={busqueda.fechaCreacionFin || ""}
              onChange={handleChange}
              onFocus={handleFocusCreacionFin}
              onBlur={handleBlurCreacionFin}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </div>
        <div>
          <Button className="btn-buscador" onClick={clearAllFilters}>
            <ImCross />
          </Button>
        </div>
        <div>
          <Button className="btn-buscador" onClick={() => getBusqueda()}>
            <BsSearch />
          </Button>
        </div>
      </div>

      <style jsx="true">
        {`
          .btn-buscador {

            padding: 5px 10px;
            color: #ffffff;
            background-color: #92004d;
            border-radius: 4px;
            font-weight: bold;
            width: -moz-fit-content;
            width: fit-content;
            align-self: flex-end;
            border: none;
            min-width: 40px;
            height: 40px;
            margin: 5px 10px 5px;
          }
          .btn-buscador:hover {
            color: black;
            background-color: #dee2e6;
          }
        `}
      </style>
    </>
  );
}

export default withRouter(FiltrosVentas);