import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { formatoFechas } from "../functions/funciones";
import { updateDocsSupp, validateDocsSupp } from "../services/docs.service";

import {
  createDeclRes,
  updateDeclRes,
  validateDeclRes,
} from "../services/declRes.service";

export const subirDocumentacionProveedor = async (
  ruta,
  id,
  idDoc,
  rol,
  nif
) => {
  return new Promise(async (resolve, reject) => {
    try {
      var el = document.getElementById("fileElem");
      let toastInfo = undefined;
      const swalConfig = {
        title: "Subir documentacion<br/>",
        showCancelButton: true,
        confirmButtonText: "Guardar  y elegir archivo",
        confirmButtonColor: "rgb(130, 130, 130)",
        cancelButtonText: "Cancelar",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-3",
        },
        focusConfirm: false,
      };
      if (idDoc !== 1 && idDoc !== 4 && idDoc !== 5) {
        swalConfig.html =
          '<div><p>Indica la fecha de caducidad del documento</p><input type="date" id="fecha" style="margin: 20px 0" class="swal2-input"></div>';
      }

      Swal.fire({
        ...swalConfig,
        focusConfirm: false,
        preConfirm: () => {
          let fecha = null;
          if (idDoc !== 1 && idDoc !== 4 && idDoc !== 5) {
            fecha = formatoFechas(
              Swal.getPopup().querySelector("#fecha").value
            );
            if (!fecha || isNaN(new Date(fecha).getTime())) {
              Swal.showValidationMessage(`fecha caducidad`);
            }
          }
          const data = {
            tipoDoc: idDoc,
            fechaCaducidad: fecha,
            pendiente:
              rol === "ADMIN" || rol === "CONSUL" || rol === "TECH"
                ? false
                : true,
            avisado: false,
            segundoAviso: false,
            validado:
              rol === "ADMIN" || rol === "CONSUL" || rol === "TECH"
                ? true
                : false,
          };
          return { data: data };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          el.click();
          el.onchange = () => {
            toastInfo = toast.info("Guardando datos", { autoClose: false });

            updateDocsSupp(el.files[0], nif, id, result.value.data)
              .then((res) => {
                toast.update(toastInfo, {
                  render: "El archivo se ha actualizado correctamente",
                  type: toast.TYPE.SUCCESS,
                  autoClose: true,
                });
                resolve(true);
              })
              .catch((err) => {
                console.log(err);
                toast.update(toastInfo, {
                  render: "Error al actualizar el archivo",
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
          };
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const subirDocumentacionPerfilProveedor = async (
  ruta,
  id,
  idDoc,
  rol,
  nif
) => {
  //creamos todo dentro de un promise para que cuando acabe de subirse se actualice la info en la vista sin necesidad de recargar pagina
  return new Promise(async (resolve, reject) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 200));
      var el = document.getElementById("fileElem");
      let toastInfo;
      const data = {
        tipoDoc: idDoc,
        pendiente:
          rol === "ADMIN" || rol === "CONSUL" || rol === "TECH" ? false : true,
        avisado: false,
        segundoAviso: false,
        validado:
          rol === "ADMIN" || rol === "CONSUL" || rol === "TECH" ? true : false,
      };
      el.click();
      el.onchange = () => {
        toastInfo = toast.info("Guardando datos", { autoClose: false });
        updateDocsSupp(el.files[0], nif, id, data)
          .then((res) => {
            toast.update(toastInfo, {
              render: "El archivo se ha actualizado correctamente",
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            toast.update(toastInfo, {
              render: "Error al actualizar el archivo",
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
      };
    } catch (error) {
      // En caso de error, rechaza la promesa
      reject(error);
    }
  });
};

export const subirDocumentacionCentroTrabajo = (
  nombreDoc,
  finca,
  nifCentroTrabajo,
  callback
) => {};

export const subirDocumentacionComunidad = (nombreDoc, finca, callback) => {};

export const abrirArchivo = async (ruta) => {
  if (!ruta) {
    // toast.error("No existe el archivo")
    return;
  } else {
    try {
      const url = `${process.env.REACT_APP_STORAGE_URL}${ruta}`;
      console.log(url);
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error al descargar el archivo");
    }
  }
};

export const verDocumentacion = (ruta, id, idDoc, rol, nif) => {
  //creamos todo dentro de un promise para que cuando acaben las funciones se actualice la info en la vista sin necesidad de recargar pagina
  return new Promise(async (resolve, reject) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 200));
      Swal.fire({
        title: "¿Qué quieres hacer con el documento?",
        text: " Subir un nuevo documento implica sobreescribir el anterior",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Ver",
        confirmButtonColor: "rgb(130, 130, 130)",
        denyButtonText: "Sobreescribir",
        denyButtonColor: "rgb(130, 130, 130)",
        cancelButtonText: "Borrar documento",
        cancelButtonColor: "#892828",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-3",
          denyButton: "order-2",
        },
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          abrirArchivo(ruta);
        } else if (result.isDenied) {
          const subirDocPromise = subirDocumentacionProveedor(
            ruta,
            id,
            idDoc,
            rol,
            nif
          );
          subirDocPromise.then(resolve).catch(reject); //esperar a ejecutar la funcion para enviar la promesa
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // const deleteDocPromise = deleteDocsSupp(nif, id)
          const deleteDocPromise = validateDocsSupp(nif, id, {
            validado: false,
          });
          deleteDocPromise
            .then(resolve)
            .then(toast.success("Documento eliminado correctamente"))
            .catch(reject);
        }
      });
    } catch (error) {
      // En caso de error, rechaza la promesa
      reject(error);
    }
  });
};
export const validarDocumentacion = (ruta, id, nif,idtypeDoc) => {
  return new Promise(async (resolve, reject) => {
    try {
      let toastInfo = undefined;
      abrirArchivo(ruta);
      const swalConfig = {
        title: "¿Qué quieres hacer con el documento?",
        text: " Si no se valida se borrará",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Validar",
        confirmButtonColor: "#7bc977",
        denyButtonText: "No validar",
        denyButtonColor: "#FF5252",
        cancelButtonText: "Cancelar",
        customClass: {
          actions: "my-actions",
          confirmButton: "order-3",
          denyButton: "order-2",
        },
        focusConfirm: false,
      };
      if (idtypeDoc !== 1 && idtypeDoc !== 4 && idtypeDoc !== 5) {
        swalConfig.html =
          '<div><p>Indica la fecha de caducidad del documento</p><input type="date" id="fecha" style="margin: 20px 0" class="swal2-input"></div>';
      }
      Swal.fire({
        ...swalConfig,
        preConfirm: () => {
          let fecha = null;
          if (idtypeDoc !== 1 && idtypeDoc !== 4 && idtypeDoc !== 5) {
            fecha = formatoFechas(
              Swal.getPopup().querySelector("#fecha").value
            );
          }

          if (idtypeDoc !== 1 && idtypeDoc !== 4 && idtypeDoc !== 5) {
            if (!fecha || isNaN(new Date(fecha).getTime())) {
              Swal.showValidationMessage(`fecha caducidad`);
            }
          }
          const data = {
            fechaCaducidad: fecha,
            validado: true,
          };
          return { data: data };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          toastInfo = toast.info("Guardando datos", { autoClose: false });
          validateDocsSupp(nif, id, result.value.data)
            .then((res) => {
              toast.update(toastInfo, {
                render: "El archivo se ha validado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
              resolve(true);
            })
            .catch((err) => {
              console.log(err);
              toast.update(toastInfo, {
                render: "Error al validar el archivo",
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            });
        } else if (result.isDenied) {
          validateDocsSupp(nif, id, result.value.data)
            .then((res) => {
              // toast.update(toastInfo, {
              //   render: "El archivo se ha validado correctamente",
              //   type: toast.TYPE.SUCCESS,
              //   autoClose: true
              // });
              resolve(true);
            })
            .catch((err) => {
              console.log(err);
              // toast.update(toastInfo, {
              //   render: "Error al validar el archivo",
              //   type: toast.TYPE.ERROR,
              //   autoClose: true
              // });
            });
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const borrarArchivo = (ruta) => {
  // Por hacer
};

export const subirDeclaracionResponsable = async (
  nifProv,
  idAAFF,
  ruta,
  idDeclRes,
  showAlert
) => {
  // //new
  // try {
  //   console.log(nifProv, idAAFF, ruta, idDeclRes);

  //   const result = await showAlert({
  //     title: "Subir declaración responsable",
  //     confirmButtonText: "Guardar  y elegir archivo",
  //     cancelButtonText: "Cancelar",
  //     confirmButtonColor:"rgb(130, 130, 130)",
  //     preConfirm: () => {
  //       const data = { aaff_id: idAAFF };
  //       return data;
  //     },
  //   });

  //   if (result.isConfirmed) {
  //     console.log(result);

  //   }

  // } catch (error) {
  //   console.log(error);
  // }

  //creamos todo dentro de un promise para que cuando acabe de subirse se actualice la info en la vista sin necesidad de recargar pagina
  return new Promise(async (resolve, reject) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 200));
      var el = document.getElementById("fileElem");
      let toastInfo = undefined;
      Swal.fire({
        title: "Subir declaración responsable",
        showCancelButton: true,
        confirmButtonText: "Guardar  y elegir archivo",
        confirmButtonColor: "rgb(130, 130, 130)",
        cancelButtonText: "Cancelar",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-3",
        },
        focusConfirm: false,
        preConfirm: () => {
          const data = {
            aaff_id: idAAFF,
          };
          return { data: data };
        },
      }).then((result) => {
        console.log(result);

        if (result.isConfirmed) {
          //si se le da al boton de guardar
          console.log("passed");

          el.click();
          el.onchange = () => {
            console.log("entro al");

            if (idDeclRes === undefined) {
              //si no hay ruta se sube la documentación y si ya hay un documento, se actualiza.
              console.log("entro");
              toastInfo = toast.info("Guardando datos", { autoClose: false });
              createDeclRes(el.files[0], nifProv, result.value.data)
                .then((res) => {
                  toast.update(toastInfo, {
                    render: "El archivo se ha subido correctamente",
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                  });
                  resolve(true);
                })
                .catch((err) => {
                  console.log(err);
                  toast.update(toastInfo, {
                    render: "Error al subir el archivo",
                    type: toast.TYPE.ERROR,
                    autoClose: true,
                  });
                });
            } else {
              console.log("entro al else");
              updateDeclRes(el.files[0], idDeclRes)
                .then((res) => {
                  toast.update(toastInfo, {
                    render: "El archivo se ha actualizado correctamente",
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                  });
                  resolve(true);
                })
                .catch((err) => {
                  console.log(err);
                  toast.update(toastInfo, {
                    render: "Error al actualizar el archivo",
                    type: toast.TYPE.ERROR,
                    autoClose: true,
                  });
                });
            }
          };
        }
      });
    } catch (error) {
      // En caso de error, rechaza la promesa
      reject(error);
    }
  });
};

export const validarDeclRes = (ruta, id, nifAAFF) => {
  // const toastInfo = toast.info()
  return new Promise(async (resolve, reject) => {
    try {
      const toastInfo = toast.info();
      abrirArchivo(ruta);
      Swal.fire({
        title: "¿Qué quieres hacer con el documento?",
        text: " Si no se valida se borrará",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Validar",
        confirmButtonColor: "#7bc977",
        denyButtonText: "No validar",
        denyButtonColor: "#FF5252",
        cancelButtonText: "Cancelar",
        customClass: {
          actions: "my-actions",
          confirmButton: "order-3",
          denyButton: "order-2",
        },
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          validateDeclRes(nifAAFF, id, { validate: true })
            .then((res) => {
              toast.update(toastInfo, {
                render: "El archivo se ha validado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
              resolve(true);
            })
            .catch((err) => {
              console.log(err);
              toast.update(toastInfo, {
                render: "Error al validar el archivo",
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            });
        } else if (result.isDenied) {
          validateDeclRes(nifAAFF, id, { validate: false })
            .then((res) => {
              resolve(true);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const verDeclRes = (nifProv, idAAFF, nifAAFF, ruta, idDeclRes) => {
  // creamos todo dentro de un promise para que cuando acaben las funciones se actualice la info en la vista sin necesidad de recargar pagina
  return new Promise(async (resolve, reject) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 200));
      Swal.fire({
        title: "¿Qué quieres hacer con el documento?",
        text: " Subir un nuevo documento implica sobreescribir el anterior",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Ver",
        confirmButtonColor: "rgb(130, 130, 130)",
        denyButtonText: "Sobreescribir",
        denyButtonColor: "rgb(130, 130, 130)",
        cancelButtonText: "Borrar documento",
        cancelButtonColor: "#892828",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-3",
          denyButton: "order-2",
        },
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          abrirArchivo(ruta);
        } else if (result.isDenied) {
          const subirDocPromise = subirDeclaracionResponsable(
            nifProv,
            idAAFF,
            ruta,
            idDeclRes
          );
          subirDocPromise.then(resolve).catch(reject); //esperar a ejecutar la funcion para enviar la promesa
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // const deleteDocPromise = deleteDocsSupp(nifAAFF, id)
          const deleteDocPromise = validateDeclRes(nifAAFF, idDeclRes, {
            validado: false,
          });
          deleteDocPromise
            .then(resolve)
            .then(toast.success("Documento eliminado correctamente"))
            .catch(reject);
        }
      });
    } catch (error) {
      // En caso de error, rechaza la promesa
      reject(error);
    }
  });
};
