import React, { useState, useEffect, useMemo } from "react";
import Portada from "../../../images/PortadaRiesgos.png";
import Encabezado from "../../../images/EncabezadoRiesgos.png";
import Logo from "../../../images/LogoLAENEW.png";
import { withRouter } from "../../../providers/withRouter";
import CircularProgress from "@mui/material/CircularProgress";
import {
  PDFViewer,
  Document,
  View,
  Page,
  Text,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import { getAAFFPropVisit, getStatement } from "../../../services";
import PDFHandler from "../../../components/pdfs/PDFHandler";
import { riesgosData } from "../../../components/aaff/riesgos";
import { Navigate, useLocation } from "react-router-dom";
import SitacionRiesgoTablaS from "../../../components/pdfs/tablas/sitacionRiesgoTablaS";
import { transformDataPdf } from "../../../utilities/transformDataPdf";
import SituacionRiesgoTablaXl from "../../../components/pdfs/tablas/situacionRiesgoTablaXl";

const baseURL = window.location.origin;

const InformeRiesgos = (props) => {

  const styles = StyleSheet.create({
    docTitle: {
      position: "absolute",
      left: 70,
      right: 70,
      bottom: "70px",
      textAlign: "center",
      fontSize: "16px",
      color: "#B50655" || "#023867",
      fontFamily: "Roboto",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    imgPrincipal: {
      height: "400px",
      width: "100%",
      marginTop: 30,
    },
    link: {
      fontSize: "8px",
      marginLeft: 10,
      marginBottom: 30,
    },
    body: {
      paddingBottom: 65,
      paddingHorizontal: "0cm",
    },
    h1: {
      marginBottom: "30px",
      fontSize: "14px",
      fontWeight: "600",
    },
    h2: {
      fontSize: "11px",
      fontWeight: "600",
      marginTop: "30px",
      marginBottom: "30px",
    },
    h3: {
      fontSize: "9px",
      width: "100%",
      padding: "5px 10px",
      textTransform: "uppercase",
      fontWeight: "bold",
      textDecoration: "underline",
    },
    h4: {
      color: "#ab2a3e",
      marginBottom: "10px",
      marginTop: "20px",
      fontSize: "9px",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "10px",
      marginTop: "15px",
      marginBottom: "5px",
    },
    ul: {
      flexDirection: "column",
      width: 150,
    },
    ol: {
      lineHeight: "1.8",
      flexDirection: "row",
      marginBottom: 4,
      fontSize: "7px",
    },
    p: {
      padding: "5px 10px",
      lineHeight: "1.8",
      textAlign: "justify",
      fontSize: "7px",
    },
    imgP: {
      width: "100px",
      marginBottom: "30px",
    },
    subp: {
      lineHeight: "1.8",
      textAlign: "justify",
      marginLeft: "8px",
      fontSize: "8px",
    },
    br: {
      display: "block",
      margin: "10px 0 2px 0",
      lineHeight: "5px",
      content: " ",
    },
    brPlus: {
      marginTop: "210px",
    },
    brText: {
      display: "block",
      margin: "2px 0 2px 0",
      lineHeight: "5px",
      content: " ",
    },
    footerSpace: {
      height: 50,
    },
    espacio: {
      display: "block",
      marginBottom: "5px",
    },
    page: {
      size: "A4 portrait",
      margin: "2cm",
    },
    pagebreak: {
      clear: "both",
      pageBreakAfter: "always",
    },
  });

  const location = useLocation();
  const [fincas, setFincas] = useState([]);
  const [riesgos, setRiesgos] = useState([]);
  const [aaff, setAAFFS] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { key: "riesgo", value: "Riesgo identificado" },
    { key: "origen", value: "Origen" },
    { key: "medidas", value: "Medidas Propuestas" },
    { key: "fincas", value: "Comunidades afectadas" },
  ];

  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate replace to="/login" state={{ from: location }} />;
  }

  const fetchData = async () => {
    try {

      const response = await getAAFFPropVisit(props.params.nif);
      const riesgosFincas = processResponse(response);
      setAAFFS(response);
      setFincas(response?.properties);
      setRiesgos(riesgosFincas);
      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };

  const processResponse = (response) => {
    const statementArrays = {};
    const respRules = {
      3: "si",
      2: "si",
      5: "si",
      6: "si",
      7: "si",
      8: "si",
      9: "no",
      11: "no",
      12: "no",
      14: "no",
      15: "no",
      16: "no",
      17: "no",
      18: "no",
      19: "no",
      20: "no",
      22: "no",
      23: "no",
      24: "no",
      27: "no",
      28: "no",
      29: "no",
      30: "no",
      32: "no",
      33: "no",
      34: "no",
      35: "no",
      36: "no",
      37: "no",
      38: "no",
      39: "no",
      40: "no",
      41: "no",
      43: "si",
      47: "si",
      48: "no",
      49: "no",
      50: "no",
      51: "no",
      53: "no",
      54: "no",
      56: "no",
      57: "no",
      58: "no",
      59: "no",
      60: "no",
      61: "no",
      62: "no",
    };

    response?.properties?.forEach((property) => {
      if (property.workcenters?.length > 0) {
        property.workcenters.forEach((workcenter) => {
          workcenter.visitSheets?.forEach((sheet) => {
            sheet.questions.forEach((question) => {
              if (respRules[question.statements_id] === question.resp) {
                if (!statementArrays[question.statements_id]) {
                  statementArrays[question.statements_id] = new Set();
                }
                statementArrays[question.statements_id].add(
                  workcenter.razonSocial
                );
              }
            });
          });
        });
      } else {
        property.visitSheets?.forEach((sheet) => {
          sheet.questions.forEach((question) => {
            if (respRules[question.statements_id] === question.resp) {
              if (!statementArrays[question.statements_id]) {
                statementArrays[question.statements_id] = new Set();
              }
              statementArrays[question.statements_id].add(property.razonSocial);
            }
          });
        });
      }
    });

    return riesgosData
      .map((riesgo) => ({
        ...riesgo,
        fincas: Array.from(statementArrays[riesgo.key] || []),
      }))
      .filter((riesgo) => riesgo.fincas.length > 0);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress
          style={{
            color: "#92004D",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <PDFHandler style={{ width: "100%", height: "100vh" }}>
          <Document size="A4" margin="2cm">
            <Page>
              <View>
                <Image
                  src={Portada}
                  alt="Portada"
                  style={{ height: "100vh" }}
                />
                <Text style={styles.docTitle}>{aaff.razonSocial}</Text>
              </View>
            </Page>

            <Page style={styles.body}>
              <View style={{ marginTop: 0, marginHorizontal: "0cm" }} fixed>
                <View>
                  <Image
                    src={Encabezado}
                    style={{
                      marginHorizontal: "auto",
                      marginTop: 20,
                      width: "80%",
                      float: "left",
                      display: "inline",
                    }}
                  />
                </View>
              </View>
              <View style={{ marginHorizontal: "1cm" }}>
                <Text style={styles.p}>
                  A continuación se detalla el resumen de los diferentes riesgos
                  identificados en las comunidades de propietarios
                </Text>
                <Text style={styles.br}></Text>
                {/* First Small Table */}
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SitacionRiesgoTablaS
                    title={"INFORME DE RIESGOS"}
                    data={transformDataPdf(aaff)}
                  ></SitacionRiesgoTablaS>
                </View>

                <Text style={styles.br}></Text>
                <Text style={styles.br}></Text>
                <Text style={styles.h3}>Objeto</Text>
                <Text style={styles.br}></Text>
                <Text style={styles.p}>
                  El informe de riesgos tiene por objeto proponer medidas en
                  base a los riesgos identificados en las comunidades de
                  propietarios.
                </Text>
                <Text style={styles.br}></Text>
                <Text style={styles.h3}>Alcance</Text>
                <Text style={styles.br}></Text>
                <Text style={styles.p}>
                  El presente informe afecta a todas las comunidades de
                  propietarios visitadas de {aaff?.razonSocial}.
                </Text>
                <Text style={styles.br}></Text>
                <Text style={styles.h3}>Metodología</Text>
                <Text style={styles.br}></Text>
                <Text style={styles.p}>
                  Para elaborar el informe se tendrán en cuenta los riesgos
                  identificados durante la visita por parte del técnico de
                  prevención de riesgos laborales, que propondrá medidas
                  preventivas y/o correctoras con el fin de minimizar dichos
                  riesgos.
                </Text>
                <Text style={styles.br}></Text>
                <Text style={styles.br}></Text>
                <Text style={styles.h3}>Ver Riegos</Text>
                <Text style={styles.link}>
                  <Link
                    src={`${baseURL}/informe-riesgos/${props.params.nif}`}
                    target="_blank"
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    Informe de riesgos más a detalle
                  </Link>
                </Text>
              </View>
              <View style={{ marginHorizontal: "1cm" }}>
                {/*Sugunda Tabla Riesgos*/}
                {riesgos.length !== 0 && (
                  <SituacionRiesgoTablaXl
                    columns={columns}
                    data={riesgos}
                  ></SituacionRiesgoTablaXl>
                )}
              </View>
              <View style={{ marginHorizontal: "1cm" }}>
                <Text style={styles.br}></Text>
                <Text style={styles.br}></Text>
                <Text style={styles.p}>
                  Agradecemos la atención prestada por el personal de{" "}
                  {aaff?.razonSocial} en la gestión de las visitas a las
                  comunidades así como en la continua colaboración con los
                  proveedores a los que solicitamos la documentación necesaria
                  para cumplir la coordinación de actividades empresariales.
                </Text>
                <Text style={styles.br}></Text>
                <Text style={styles.p}>
                  Quedamos a su entera disposición para aclarar cualquier duda,
                </Text>
                <Image alt="logo" src={Logo} style={styles.imgP} />
              </View>
            </Page>
          </Document>
        </PDFHandler>
      )}
    </>
  );
};

export default withRouter(InformeRiesgos);