import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formatoFechasEs } from "../../functions/funciones";
import NoVisitado from "../noVisitadoComunidad";
import { Box, Typography, Tooltip } from "@mui/material";
import { DataTable } from "../../components";
import "./comunidad.css";
import Add from "../../icons/add.svg";
import Swal from 'sweetalert2';

import { DeleteSale, getSales } from "../../services";
import { toast } from "react-toastify";
import FiltrosVentas from "../filtros/filtrosVentas";
import { FaPencilAlt } from "react-icons/fa";

import { TbCertificate } from "react-icons/tb";
import { IoVideocam } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { BsTrash } from "react-icons/bs";
import { UsuarioContext } from "../../providers/userContext";
import Enlace from "../Enlace";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../functions/parametrosURL/funcionesParamsURL';
import ExportarListaServicios from "../../routes/pages/admin/ExportarListaServicios";


export default function ComunidadDocs(props) {

  const { idPropiedad, grupoServicio } = props

  const user = useContext(UsuarioContext)

  const canEdit = user.rol === "ADMIN" || user.rol === "CONSUL"

  const [primeraCarga, setPrimeraCarga] = useState(true);
  const [listaVentas, setListaVentas] = useState([]);
  const [loading, setLoading] = useState(true);
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [recargar, setRecargar] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  const navigate = useNavigate();
  const location = useLocation();
 

  const clearFilters = () => {
    if (user.rol === "CONSUL" && localStorage.getItem("hasReloaded") === "true") {
      setBusqueda({
        consul_id: user.id 
      });
    }
    else if(user.rol === "CONSUL"){
      setBusqueda({
        consul_id: user.id 
      });
    }
    
    else{
      setBusqueda({});
    }
    
    setClearFilterActivo(true);
  }



  const comunidadesRows = [
    {
      id: "color",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto: false,
      overrideFunc: (data, row) => (
        <Typography
          style={{ width: '3px', height: '6vh', background: data }}>
        </Typography>
      )
    },
    {
      id: "ano",
      numeric: false,
      disablePadding: false,
      label: "AÑO",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <Typography
          style={{ background: row, padding: '18px 10px' }}
        >
          {data}
        </Typography>
      ),
    },
    {
      id: "servicio",
      numeric: false,
      disablePadding: false,
      label: "SERVICIO",
      noObjeto:true,
    },
    ...(idPropiedad === undefined ? [{
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
      noObjeto:true,
    }] : []),
    {
      id: "comunidad",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      noObjeto:true,
      overrideFunc: (data, row) => (
        idPropiedad === undefined ? 
          <Link to={user.rol === "AAFF" ? "/comunidades/" + row.finca.nif : "/aaff/" + row.nif_aaff + "/comunidades/" + row.finca.nif} >{data}</Link>
        : idPropiedad !== undefined && row.isWorkcenter ?
          <Typography>
            {data}
          </Typography>
        :null
      ),
    },
    ...(idPropiedad === undefined ? [{
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
      noObjeto:true,
    }] : []),
    
    ...(user.rol !== 'TECH' ?
    [{
      id: "saleDate",
      numeric: false,
      disablePadding: false,
      label: "FECHA VENTA",
      
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    }]:[]),

    {
      id: "documentos",
      numeric: false,
      disablePadding: false,
      label: "DOCUMENTOS",
      colSpan: 4,
      noObjeto:true,
      overrideFunc: (data, row) => (
        <Typography style={{ display: 'flex', gap: 5, color: '#fff' }}>
          {(row.all_row.serviceprops.id === 1 || row.all_row.serviceprops.id === 3 || row.all_row.serviceprops.id === 6 || row.all_row.serviceprops.id === 7) && (
            <>

              {renderDocs("Hoja visita", <HiClipboardDocumentList />, "hoja-visita", row.all_row, "cae")}


              {renderDocs("Identificación de riesgos", <MdHealthAndSafety />, "identificacion-riesgos", row.all_row, "cae")}


              {renderDocs("Plan emergencia", <IoIosWarning />, "plan-emergencia", row.all_row, "cae")}

            </>
          )}
          {(row.all_row.serviceprops.id === 2 || row.all_row.serviceprops.id === 4) && (
            <>
              {renderDocs("Certificado", <TbCertificate />, row.all_row.serviceprops.id === 4 ? 'certificado-auditoria' : 'certificado-implantacion', row.all_row, "lopd")}
              {renderDocs("Implantación", <TiDocumentText />, 'documentacion-implantacion', row.all_row, "lopd")}

              {row.all_row.serviceprops.id === 4 ?
                renderDocs("Auditoría", <TiDocumentText />, 'documentacion-auditoria', row.all_row, "lopd")
                : null}

              {row.all_row.detailsLopd[0]?.surveillanceVideo ? renderDocs("Video vigilancia", <IoVideocam />, "video-vigilancia", row.all_row, "lopd") : ''}

            </>
          )}
          {(row.all_row.serviceprops.id === 5) && (
            <>
              {renderDocs("LOPD", <TbCertificate />, 'lopd', row.all_row, "lopd")}
            </>
          )}
        </Typography>
      )
    },
    ...(props.grupoServicio === 1 || props.grupoServicio === undefined ?
      [{
        id: "tecnico",
        numeric: false,
        disablePadding: false,
        label: "TÉCNICO",
        noObjeto:true,
        overrideFunc: (data, row) => data,
      }] : []),

    {
      id: "fechaRealizada",
      numeric: false,
      disablePadding: false,
      label: "FECHA REALIZADA",
      noObjeto:true,
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    },
    ...(canEdit ?
      [{
        id: "editar",
        numeric: false,
        disablePadding: true,
        label: "",
        noObjeto:true,
        overrideFunc: (data, row) => (
          <Typography
            style={{ width: '100px' }}>
            {props.grupoServicio === 1 || props.grupoServicio === undefined ? <NoVisitado finca={row.all_row} nif={row.finca.nif} tipo={"finca"} /> : null}
            <Link to={`/servicios/editar/${row.editar.id}`} ><FaPencilAlt /></Link>
            <Link to={''} onClick={() => comprobarEliminarVenta(row.editar.id)} style={{ marginLeft: '10px' }}><BsTrash /></Link>
          </Typography>
        ),
      }] : []),
  ];
  const enlaceToDocType = {
    'identificacion-riesgos': 'evaluacionRiesgos',
    'hoja-visita': 'hojaVisita',
    'plan-emergencia': 'planEmergencia',
    'lopd': 'LOPD'
  }

  const renderDocs = (nombre, icono, enlace, row, tipo) => {

    let documento;
    let url;
    if (tipo === "lopd" && enlace !== 'lopd') {
      documento = row.detailsLopd?.find(obj => obj.sale_id === row.id);
    }
    if (tipo === "lopd" && enlace === 'lopd') {
      documento = true
    }
    if (tipo === 'cae') {
      documento = row.detailsCae?.find(obj => obj.sale_id === row.id);
    }
    if (!documento) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: 16 }}>
          <Tooltip title={nombre} style={{ fontSize: '16px' }}>
            <small
              className="docsStatus"
              style={{ background: '#FF5252', height: 'auto' }}
            >
              {icono}
            </small>
          </Tooltip>
        </div>
      );
    }

    const { pendiente, visitada } = documento;

    if (tipo === "lopd" && enlace !== 'lopd' || (tipo === 'cae' && (pendiente || visitada))) {
      if (row.workcenters === null) {
        url = `/doc-render/${row.properties?.nif}/${row.id}/${enlace}`
      } else {
        url = `/doc-render/${row.properties?.nif}/${row.workcenters.id}/${row.id}/${enlace}`
      }
    }
    if (tipo === "lopd" && enlace === 'lopd') {
      url = process.env.REACT_APP_STORAGE_URL + row.docsProps?.find(doc => doc.type === enlaceToDocType[enlace])?.ruta
    }
    if (tipo === 'cae' && !documento?.visitSheet_id) {
      url = process.env.REACT_APP_STORAGE_URL + row.docsProps?.find(doc => doc.type === enlaceToDocType[enlace])?.ruta
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

        {!pendiente && tipo !== 'lopd' && !visitada ?
          <Tooltip title={nombre} style={{ fontSize: '16px' }}>
            <small
              className="docsStatus"
              style={
                { background: '#FF5252' }
              }
            >
              {icono}
            </small>
          </Tooltip>
          :
          <Enlace to={url} target={"_blank"}>
            <Tooltip title={nombre} style={{ fontSize: '16px' }}>
              <small
                className="docsStatus"
                style={
                  tipo === 'lopd' || visitada ?
                    { background: '#7bc977' }
                    : pendiente ?
                      { background: '#FCBA03' }
                      : { background: '#FF5252' }
                }
              >
                {icono}
              </small>
            </Tooltip></Enlace>
        }

      </div>
    );
  };


  // useEffect(() => {
  //   fetchData();
  // },[recargar])

  // listado simple con los ct independientes de las comunidades

  const color = ['#0767A2', '#B96800', '#409FD9', '#EE8D11', "", "#77AF6E", "#77D860"]
  const formatData = (data) => {
    return data.map((row) => ({
      color: color[row.serviceprops.id-1],
      ano: new Date(row.saleDate).getFullYear(),
      servicio: row.serviceprops.nombre,
      nif: row.properties.nif,
      nif_aaff: row.aaff.nif,
      finca: row.properties,
      comunidad: row.workcenters?.razonSocial || row.properties?.razonSocial,
      codigoPostal: row.properties.codigoPostal,
      saleDate: row.saleDate,
      fechaEnvio: row.adviceDate,
      all_row: row,
      noVisita: typeof row.detailsCae?.[0] !== 'undefined' && row.detailsCae[0]?.unRealized,
      fechaRealizada: row.servp_id === 2 || row.servp_id === 4 ? row.adviceDate : !row.detailsCae?.[0]?.unRealized && row.detailsCae?.[0]?.visitSheet?.createdAt !== null && row.detailsCae?.[0]?.visitSheet?.createdAt !== undefined ? row.detailsCae[0]?.visitSheet?.createdAt : '',
      editar: row,
      details_lopd: row.detailsLopd?.length,
      details_cae: row.detailsCae?.[0]?.visitada === undefined ? null : row.detailsCae[0].visitada,
      unRealized: row.detailsCae?.[0]?.unRealized,
      tecnico: row?.technicians ? row?.technicians?.nombre + " " + row?.technicians?.apellidos : "",
      isWorkcenter: row.workcenters ? true : false
    }));
  };
  /* @JUANK PENDIENTE A USAR EL HOOK*/
  // const formatData = (data) => {
  //   const formattedData = []
  //   data.map((row) => {
  //     formattedData.push({

  //     })
  //     if(Object.keys(row.workcenters)?.length > 0){
  //       formattedData.push({})
  //     }
  //   })
  // }

  const handleCallback = () => {
    setRecargar(true);
  };

  const comprobarEliminarVenta = async (id) => {
    // Llama a la función importada y pasa la devolución de llamada
    eliminarVenta(id).then((resultado) => {
      handleCallback(resultado)
    })
  };

  const eliminarVenta = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Swal.fire({
          title: "¿Quieres eliminar la venta?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#888",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar"
        });

        if (result.isConfirmed) {
          const toastInfo = toast.info("Eliminando venta", { autoClose: false });
          await DeleteSale(id)
            .then(() => {
              toast.update(toastInfo, {
                render: "Eliminado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
            })
            .catch(error => {
              console.log(error);
              toast.update(toastInfo, {
                render: "Error al eliminar",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
              reject(error);
            });
        } else {
          // Si el usuario cancela la eliminación
          resolve(false);
        }
      } catch (error) {
        console.error("Error al eliminar la venta:", error);
        reject(error);
      }
    });
  };
  



  const fetchData = async ({ offset, limit, filtros = busqueda,order,orderBy } = {}) => {


    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order = order || filtroOrder.orden || 'ASC';

    if (primeraCarga) {
      if (filtros.consul_id === undefined && user.rol === 'CONSUL') {
        filtros.consul_id = user.id
      } else if (filtros.consul_id === "0") {
        delete filtros.consul_id
      }
      setPrimeraCarga(false);
    }


    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros,order, orderBy, grupoServicio);

    try {
      setLoading(true);
      if (props.idPropiedad === undefined) {
        const payload = {
          filtros: user.rol === 'ADMIN' ? filtros : { ...filtros },
          params: { page: offset, limit: limit, order, orderBy },
        };

        const response = await getSales(payload)
        const { meta, data } = response;
        setListaVentas(data);
        setData({
          meta,
          rows: formatData(data),
        });
      } else {
        let grupoServ_id = null
        if (location.pathname !== '/servicios') {
          grupoServ_id = props.grupoServicio
        }
        const payload = {
          filtros: { prop_id: props.idPropiedad, grupoServ_id },
          params: { page: offset, limit, order, orderBy },
        };
        const response = await getSales(payload)
        const { meta, data } = response;
        setListaVentas(data)
        setData({
          meta,
          rows: formatData(data),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
    setRecargar(!recargar)
    // fetchData();
  }

  return (
    <>
      <div className="pageContent tableContainer">
        {props.idPropiedad === undefined && (
          <>
            <div className="contentBar" style={{ padding: '0 10px' }}>
              <div className="contentBtns"></div>
              <Link to="/servicios/create" target={"_blank"}>
                <div className="contentButton">
                  <img src={Add} alt="Más" />
                </div>
              </Link>
            </div>
            {idPropiedad === undefined && // si esta en la ficha de la comunidad los filtros no aparecen
              <FiltrosVentas onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
            }
          </>
        )}
        <div style={{ margin:'20 0' }}>
          <ExportarListaServicios filtros={busqueda} />
        </div>
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay ventas"}
                recargar={recargar}
              />
            </Box>
          </Box>
        </div>
      </div>
      <style jsx="true">{`
        .tableContainer table tbody th.docs, .tableContainer table thead th {
          text-align: center;
        }
        .tableContainer table tbody td {
          padding: 0;
          text-align: center;
        }
      `}</style>
    </>
  );
}
