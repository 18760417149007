import React from 'react'
import { Document, Page, View, Text, Image, StyleSheet } from "@react-pdf/renderer";

export default function SituacionRiesgoTablaXl({ columns, data }) {

// 📌 Estilos para la tabla
const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontSize: 10,
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
    //   borderWidth: 1,
      borderColor: "#000",
      borderLeftWidth: 0.5,
    },
    tableRowHeader: {
      flexDirection: "row",
      backgroundColor: "#c31e64",
      color: "#fff",
      textAlign: "center",
      fontSize: 10,
      marginBottom:2
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
    },
    tableColHeader: {
      flex: 1,
      borderStyle: "solid",
      borderWidth: 0.5,
      borderColor: "#000",
      padding: 5,
      fontWeight: "bold",
      textAlign: "center",
    },
    tableCol: {
      flex: 1,
      borderStyle: "solid",
    // //   borderWidth: 0.5,
      borderColor: "#000",
      borderLeftWidth: 0.3,
      borderRightWidth: 0.5,
      padding: 5,
      fontSize: 8,
    },
    image: {
      width: 40,
      height: 38,
      marginTop: 8,
      marginLeft:35
    },
    fincaText: {
      fontSize: 8,
      marginBottom: 2,
    },
  });

  return (
    <View style={styles.table}>
    {/*  Encabezado de la Tabla */}
    <View style={styles.tableRowHeader} fixed >
      {columns.map((col, index) => (
        <Text key={index} style={styles.tableColHeader}>{col.value}</Text>
      ))}
    </View>

    {/*  Filas de Datos */}
    {data.map((item, rowIndex) => (
      <View key={rowIndex} style={styles.tableRow}>
        {columns.map((col, colIndex) => (
          <View key={colIndex} style={styles.tableCol}>
            {/*  Renderiza el campo normal */}
            {col.key !== "medidas" && col.key !== "img" && col.key !== "fincas" && (
              <Text>{Array.isArray(item[col.key]) ? item[col.key].join(", ") : item[col.key]}</Text>
            )}

            {/*  Renderiza medidas y la imagen en la misma celda */}
            {col.key === "medidas" && (
              <>
                {item.medidas.map((medida, medidaIndex) => (
                  <Text key={medidaIndex}>{medida}</Text>
                ))}
                {item.img && <Image src={item.img} style={styles.image} />}
              </>
            )}

            {/*  Renderiza el array de fincas con cada elemento en una línea */}
            {col.key === "fincas" && item.fincas.map((finca, fincaIndex) => (
              <Text key={fincaIndex} style={styles.fincaText}>{finca}</Text>
            ))}
          </View>
        ))}
      </View>
    ))}
  </View>
  )
}
