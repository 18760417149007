
import { deleteMatchFincaProv } from "../services";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { deleteComment, updateComment } from "../services/comment.service";

export const filterItems = (query, array) => {
 
  return array.filter(function (el) {
    return el.busqueda.toLowerCase().indexOf(query.toLowerCase()) > -1;
  });
};

export const formatoFechas = (fecha) => {
  var d = new Date(fecha);
  var mm = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  var dd = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  var yy = d.getFullYear();
  let nuevoFormato = yy + "-" + mm + "-" + dd;

  return nuevoFormato;
};

export const formatoFechasEs = (fecha) => {
  var d = new Date(fecha);
  var mm = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  var dd = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  var yy = d.getFullYear();
  let nuevoFormato = dd + "-" + mm + "-" + yy;

  return nuevoFormato;
};

export const monthDiff = (dateFrom, dateTo) => {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

export const daysDiff = (dateFrom, dateTo) => {
  var diasdif = dateTo.getTime() - dateFrom.getTime();
  var contdias = Math.round(diasdif / (1000 * 60 * 60 * 24)); 
  return contdias;
};

export const eliminaDuplicados = (arr) => {
  const setObj = new Set(); 

  return arr.reduce((acc, elemento) => {
    const clave = JSON.stringify(elemento.id);

    if (!setObj.has(clave)) {
      setObj.add(clave, elemento);
      acc.push(elemento);
    }
    return acc;
  }, []);
};



export const subirDeclaracionResponsable = () => {
  console.log("subirDeclaracionResponsable");
};
export const verDocumentacionProveedor = () => {
  console.log("verDocumentacionProveedor");
};
export const validarDocumentacion = () => {
  console.log("validarDocumentacion");
};
export const noValidarDocumentacion = () => {
  console.log("noValidarDocumentacion");
};

export const verDocumentacion = () => {
  console.log("verDocumentacion");
};

export const comprobarDocumentacion = () => {
  console.log("comprobarDocumentacion");
};

export const subirDocumentacion = () => {
  console.log("subirDocumentacion");
  var el = document.getElementById("fileElem");
  el.click();
};

export const desvincularProveedor = (idFinca, idProveedor) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      Swal.fire({
        title: "Desvincular proveedor?",
        showCancelButton: true,
        confirmButtonText: "Desvincular",
        confirmButtonColor: "#49cd70",
      }).then((result) => {
        
        if (result.isConfirmed) {
          
          deleteMatchFincaProv(idFinca, idProveedor)
            .then((res) => {
              
              if (res.status >= 200 && res.status < 300) {
                toast.success("proveedor desvinculado");
                resolve(true);
              } else {
                toast.error(
                  "No se ha podido desvincular el proveedor " + idProveedor
                );
              }
            })
            .catch((error) => {
              console.log(error);
              toast.error("Ha ocurrido un error");
            });
        }
      });
    }catch (error) {
      reject(error)
    }
  })
};
export const desvincularProveedorDeFincas = (fincas, idProv, aaff_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      Swal.fire({
        title: "Desvincular proveedor de todas las comunidades del aaff?",
        showCancelButton: true,
        confirmButtonText: "Desvincular",
        confirmButtonColor: "#49cd70",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          
          deleteMatchFincaProv(fincas, idProv, aaff_id)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              toast.success("proveedor desvinculado");
              resolve(true);
            } else {
              toast.error(
                "No se ha podido desvincular de la finca"
              );
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Ha ocurrido un error");
          });
        }
      });
    }catch (error) {
      reject(error)
    }
  })
};

export const desvincularProveedores = (idFinca, provs) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      Swal.fire({
        title: "Desvincular todos los proveedores de la comunidad?",
        showCancelButton: true,
        confirmButtonText: "Desvincular",
        confirmButtonColor: "#49cd70",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const provsIds = provs.map(p => p.id)
          deleteMatchFincaProv(idFinca, provsIds)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              toast.success("proveedor desvinculado");
              resolve(true);
            } else {
              toast.error(
                "No se ha podido desvincular el proveedor"
              );
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Ha ocurrido un error");
          });
        }
      });
    }catch (error) {
      reject(error)
    }
  })
};

export const cambiarUltimaReclamacion = () => {
  console.log("cambiarUltimaReclamacion");
};

export const generarPDF = (id) => {
  const input = document.getElementById(id);
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imageWidth = canvas.width;
    const imageHeight = canvas.height;
    const ratio = Math.min(pageWidth / imageWidth, pageHeight / imageHeight);
    const centerImageWidth = imageWidth * ratio;
    const centerImageHeight = imageHeight * ratio;
    const leftPosition = (pageWidth - centerImageWidth) / 2;
    const topPosition = (pageHeight - centerImageHeight) / 2;
    pdf.addImage(
      imgData,
      "PNG",
      leftPosition,
      topPosition,
      centerImageWidth,
      centerImageHeight
    );
    pdf.save("file.pdf");
  });
};

export const numProveedoresAAFF = (data) => {
  //sumar todos los proveedores unicos de un aaaff
  const nifUnicos = [...new Set(data.reduce((acumulador, objeto) => {
      objeto.suppliers.forEach(supplier => {
        if(supplier.status){
          acumulador.push(supplier.nif);
        }
      });
      return acumulador;
    }, []))];
    
    return nifUnicos;
  
}
export const docsPendientesProvs = (data) => {
  const pendientesDocs = data
    .map((elemento) =>
      elemento.suppliers.map((proveedor) =>
        proveedor.docssupps.filter((docssupp) => docssupp.pendiente === true).length
      )
    )
    .flat(2)
    .reduce((total, count) => total + count, 0);
  const pendientesDeclRes = data
    .map((elemento) =>
      elemento.suppliers.map((proveedor) =>
        proveedor.docssupps.filter((dr) => dr.pendiente === true).length
      )
    )
    .flat(2)
    .reduce((total, count) => total + count, 0);
  const conteoPendientes = pendientesDocs + pendientesDeclRes
  return conteoPendientes;
  
}
export const docsPendientesProv = (data) => {
  console.log(data)
  const pendientesDocs = data.docssupps?.filter((docssupp) => docssupp.pendiente === true).length || 0
  const pendientesDeclRes = data.declres?.filter((dr) => dr.pendiente === true).length || 0
  const conteoPendientes = pendientesDocs + pendientesDeclRes
  console.log(data.nif, pendientesDocs, pendientesDeclRes)
  return conteoPendientes;
}

export const municipiosIniciales = (provinces, municipalities, provincia) => {
  let municipios = []
  let codProvincia = ''
  provinces.map( p => {
    if(p.name === provincia){
      codProvincia = p.code
    }
  })
  municipalities.map( m => {
    if(m.provCode === codProvincia){
      
      municipios.push({...m, name: cambiarFormatoNombre(m.name), value: m.name})
    }
  })
  return municipios
}

export const cambiarFormatoNombre = (nombre) => {
  
  if (nombre?.includes(",")) {
    const partes = nombre.split(", ");
    if (partes.length === 2) {
      
      return partes[1] + " " + partes[0];
    }
  }
  
  return nombre;
}

export const eliminarComentario = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      Swal.fire({
        title: "Eliminar comentario?",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#892828",
      }).then((result) => {
        
        if (result.isConfirmed) {
          deleteComment(id)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              toast.success("Comentario eliminado");
              resolve(true);
            } else {
              toast.error(
                "No se ha podido eliminar el comentario"
              );
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Ha ocurrido un error");
          });
        }
      });
    }catch (error) {
      reject(error)
    }
  })
}
export const editarComentario = (id, userId, comm) => {
  return new Promise(async (resolve, reject) => {
    try {
      let toastInfo = undefined
      Swal.fire({
        title: "Editar comentario",
        showCancelButton: true,
        confirmButtonText: "Editar",
        confirmButtonColor: "#49cd70",
        html: `<textarea id="comentario" style="width: 100%; height: 600px; border: 1px solid #ccc; border-radius: 5px; padding: 10px;" className="swal2-input">${comm.texto}</textarea>`,
        
        preConfirm: () => {
          toastInfo = toast.info("Guardando datos", { autoClose: false });
          const comentario = Swal.getPopup().querySelector("#comentario").value;
          if (!comentario) {
            Swal.showValidationMessage(`comentario`);
          }
          const data = {
            prop_id:comm.prop_id,
            supp_id:comm.supp_id,
            aaff_id:comm.aaff_id,
            texto:comentario,
            tipo: comm.tipo
          };
          return { data: data };
        },
      }).then((result) => {
       
        if (result.isConfirmed) {
          updateComment(id, result.value.data)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              toast.update(toastInfo, {
                render: "Comentario actualizado",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
            } else {
              toast.update(toastInfo, {
                render: "No se ha podido actualizar el comentario",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Ha ocurrido un error");
          });
        }
      });
    }catch (error) {
      reject(error)
    }
  })
}

export const comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
  
  return new Promise((resolve, reject) => {
    const $canvas = document.createElement("canvas");
    const imagen = new Image();
    imagen.onload = () => {
      $canvas.width = imagen.width;
      $canvas.height = imagen.height;
      $canvas.getContext("2d").drawImage(imagen, 0, 0);
      $canvas.toBlob(
        (blob) => {
          if (blob === null) {
            return reject(blob);
          } else {
            resolve(blob);
          }
        },
        "image/jpeg",
        porcentajeCalidad / 100,
      );
    };
    imagen.src = URL.createObjectURL(imagenComoArchivo);
  });
};



export function encryptParameters(...params) {
 
  const encryptedParams = params.map(param => btoa(param)).join('/');
  return encryptedParams;
}


export function decryptParameters(encryptedParams) {
 
  const decryptedParams = encryptedParams.split('/').map(param => atob(param));
  return decryptedParams;
}

export function decryptParameter(encryptedParameter) {
  return atob(encryptedParameter);
}

export function diferenciaMesesCaducidad(renovatedDate, createdAt){
  const fechaRenovacion = renovatedDate ? new Date(renovatedDate) : "";
  const fechaCreacion = new Date(createdAt);
  const hoy = new Date();
  const diferenciaMeses = renovatedDate ? (hoy.getFullYear() - fechaRenovacion.getFullYear()) * 12 + (hoy.getMonth() - fechaRenovacion.getMonth()) : (hoy.getFullYear() - fechaCreacion.getFullYear()) * 12 + (hoy.getMonth() - fechaCreacion.getMonth());
  return diferenciaMeses
}

export function estadoServicio(id){
  const estados = ['Activa', 'Caducada', 'Baja vto', 'Baja'];
  const index = (id === null || id === undefined) ? 0 : id - 1;
  return estados[index] || 'Estado no válido';
}