import { formatoFechasEs } from "../functions/funciones";

export const transformDataPdf = (obj) => {

    return {
        "Administrador de fincas:": obj?.razonSocial,
        "Cif:": obj?.nif,
        "Municipio:": obj?.municipio,
        // "Provincia:": obj?.provincia,
        // "Código Postal:": obj?.codigoPostal || "N/A", // Manejo de null
        "Teléfono:": obj?.telefono,
        // "Nombre Contacto:": obj?.nombreContacto,
        // "Fecha Encargo:": obj?.fechaEncargo,
        // "Fecha Informe:": obj?.fechaInforme,
        "E-Mail:": obj?.user?.email,
        "Técnico:": obj?.technician
            ? `${obj?.technician?.nombre} ${obj?.technician?.apellidos}`
            : "No asignado",
        "Informe:": formatoFechasEs(obj?.fechaInforme),
        "Número comunidades:": obj?.properties.length,
        // "Creado en:": obj?.createdAt,
        // "Actualizado en:": obj?.updatedAt,
    }

}