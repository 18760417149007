import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { formatoFechasEs } from "../../../functions/funciones";

export default function SitacionRiesgoTablaS({ data, title }) {
  
  const styles3 = StyleSheet.create({
    page: {
      padding: 20,
      fontSize: 7,
    },
    table: {
      display: "table",
      width: "70%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#000",
    },
    titleRow: {
      backgroundColor: "#c31e64",
      color: "#fff",
      paddingVertical: 3, // 📌 Reduce el espacio vertical
      paddingHorizontal: 5, // 📌 Mantiene algo de margen lateral
      textAlign: "center",
      fontSize: 10,
      fontWeight: "bold",
      width: "100%", // 📌 Ajusta el ancho al 70%
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColLabel: {
      width: "50%", // Columna derecha (títulos)
      borderStyle: "solid",
      borderWidth: 0.5,
      borderColor: "#000",
      padding: 5,
      backgroundColor: "#f0f0f0", // Gris claro para destacar
      fontWeight: "bold",
      textAlign: "left",
      fontSize: 8,
    },
    tableColValue: {
      width: "50%", // Columna izquierda (valores)
      borderStyle: "solid",
      borderWidth: 0.5,
      borderColor: "#000",
      padding: 5,
      textAlign: "left",
      fontSize: 10,
    },
  });

  return (
    <View style={styles3.table}>
      {/* 📌 Fila del título dentro de la tabla */}
      <View style={styles3.titleRow}>
        <Text>{title}</Text>
      </View>

      {/* 📌 Filas con dos columnas */}
      {Object.entries(data).map(([label, value], index) => (
        <View key={index} style={styles3.tableRow}>
          <Text style={styles3.tableColLabel}>{label}</Text>
          <Text style={styles3.tableColValue}>{value}</Text>
        </View>
      ))}
    </View>
  );
}
