import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useLocation } from 'react-router-dom';
import { useRecogerURLParamsFiltros } from './../../functions/parametrosURL/funcionesParamsURL';


import {
  Button,
  Checkbox,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  TableHead,
  TableSortLabel,
  Typography,
} from "@mui/material";
import Iconify from "../../utilities/iconify";

import "./DataTable.css"

const headCells = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR DE FINCAS",
  },
  {
    id: "department",
    numeric: false,
    disablePadding: false,
    label: "COMUNIDAD",
  },
  {
    id: "medSeg",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "FECHA ALTA",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "DIRECCION",
  },
  {
    id: "cp",
    numeric: false,
    disablePadding: false,
    label: "C.P",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "SERVICIOS",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "EMPLEADOS",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "IDENTIFICACIÓN DE RIESGOS Y MEDIDAS PREVENTIVAS",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "PLAN DE EMERGENCIA",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "PLAN DE PREVENCIÓN",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "HOJA DE VISITA",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "NO VISITADA",
  },
];



// function descendingComparator(a, b, orderBy) {
//   const valueA = a[orderBy];
//   const valueB = b[orderBy];

//   if (typeof valueA === 'string' && typeof valueB === 'string') {
//     if (valueB.toLowerCase() < valueA.toLowerCase()) {
//       return -1;
//     }
//     if (valueB.toLowerCase() > valueA.toLowerCase()) {
//       return 1;
//     }
//   } else {
//     if (valueB < valueA) {
//       return -1;
//     }
//     if (valueB > valueA) {
//       return 1;
//     }
//   }

//   return 0;
// }


// function getComparator(order, orderBy) {
//   return order === "desc" 
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array = [], comparator) {
//   if (!array.length) return [];
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const EnhancedTableHead = ({
  onSelectAllClick,
  selected,
  data,
  headersData,
  order,
  orderBy,
  onRequestSort,
  enableSelection,
  enumerate,
  segundaCol,
  propCaducada
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const setHeaders = (headers) => {
    return headers.map((headCell) => (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? "right" : "left"}
        padding={headCell.disablePadding ? "none" : "normal"}
        sortDirection={orderBy === headCell.id ? order : false}
        size="small"
        noObjeto={headCell.noObjeto}
      >
        {headCell.noObjeto !== true ? (
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
            sx={{
              color: "white !important",
              "&:hover": { color: "white" },
              "&:active": { color: "white" },
              svg: { filter: "invert(1) brightness(1)" },
              fontSize: "0.8rem",
            }}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        ) :
          (
            <Typography
              sx={{
                color: "white !important",
                fontSize: "0.8rem",
              }}
            >
              {headCell.label}
            </Typography>
          )}
      </TableCell>
    ));
  };

  return (
    <TableHead sx={{ backgroundColor: "#92004D" }}>
      <TableRow

      >
        {enableSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                selected?.length > 0 && selected?.length < data?.length
              }
              checked={data?.length > 0 && selected?.length === data?.length}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all names",
              }}
            />
          </TableCell>
        )}
        {segundaCol && (
          <TableCell>
            <Typography></Typography>
          </TableCell>
        )}
        {propCaducada && (
          <TableCell>
            <Typography></Typography>
          </TableCell>
        )}
        {enumerate && (
          <TableCell>
          </TableCell>
        )}
        {headersData ? setHeaders(headersData) : setHeaders(headCells)}
      </TableRow>
    </TableHead>
  );
};

const DataTable = ({
  data,
  meta,
  headers,
  isLoading,
  onClickRow,
  fetcher,
  enableSection,
  children,
  filtros,
  busquedaActiva,
  setBusquedaActiva,
  clearFilterActivo,
  setClearFilterActivo,
  recargar,
  mensaje,
  clicable,
  enumerate = false,
  segundaCol,
  propCaducada
}) => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const pagina = parseInt(params.get("pagina")) || 1;
  const limiteParam = parseInt(params.get("limite")) || 50;

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(pagina);
  const [rowsPerPage, setRowsPerPage] = useState(limiteParam);
  const [isArrowDown, setIsArrowDown] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const { filtroOrder } = useRecogerURLParamsFiltros();

  const [order, setOrder] = useState(filtroOrder.orden ? filtroOrder.orden.toLowerCase() : '');
  const [orderBy, setOrderBy] = useState(filtroOrder.ordenadoPor);
  const [orderApi, setOrderApi] = useState(filtroOrder.orden);

  // const handleTabFilterValue = (event, newValue) => {
  //   const newData = data.filter(
  //     (row) => row[newValue] !== null || row[newValue] !== "false"
  //   );
  //   setFilteredData(newData);
  // };

  // const handleStatusClick = () => {
  //   setIsArrowDown((prevIsArrowDown) => !prevIsArrowDown);

  //   const newData = isArrowDown
  //     ? data?.filter((row) => row.status === "0")
  //     : data?.filter((row) => row.status === "1");

  //   setFilteredData(newData);

  // };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(data.map((n) => n.userIdentifier));
      return;
    }
    setSelected([]);
  };

  const handleNext = () => {
    if (setBusquedaActiva && typeof setBusquedaActiva === 'function') {
      setBusquedaActiva(false);
    }
    setPage((page) => page + 1);
  };

  const handleBack = () => setPage((page) => (page === 1 ? page : page - 1));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderApi(isAsc ? "DESC" : "ASC")
    setOrderBy(property);
  };

  // const visibleRows = useMemo(
  //   () => stableSort(data, getComparator(order, orderBy)),
  //   [order, orderBy, data]
  // );

  useEffect(() => {
    // fetcher({ offset: page, limit: rowsPerPage, filtros: filtros });
    if (busquedaActiva || clearFilterActivo) {
      fetcher({ offset: setPage(1), limit: rowsPerPage, filtros: filtros, orderBy: orderBy, order: order });
      if (setClearFilterActivo && typeof setClearFilterActivo === "function") {
        setClearFilterActivo(false);
      }
    } else {

      fetcher({ offset: page, limit: rowsPerPage, filtros: filtros, orderBy: orderBy, order: orderApi });
      // fetcher({ offset: page, limit: rowsPerPage, filtros: filtros, orderBy: orderBy ,order: order });
    }

  }, [page, rowsPerPage, recargar, filtros, orderApi, orderBy]);

  useEffect(() => {
    setPage(pagina)
    //fetcher({ offset: page, limit: rowsPerPage, filtros: filtros, orderBy: orderBy ,order: order });


  }, [pagina]);

  const visibleRows = data;


  const setTabs = (tabs) =>
    tabs.map((tab) => <Tab value={tab.value} label={tab.label} />);

  const isLastPage = !meta?.has_more_pages;

  const PaginationBar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 2,
          py: 1,
          gap: 3,
          alignItems: "center",
        }}
      >
        <Box className="lae-flex" sx={{ alignItems: "center", marginLeft: 1 }}>
          <Typography>Tamaño de página:</Typography>
          <Select
            value={rowsPerPage}
            label="Age"
            size="small"
            variant="standard"
            onChange={({ target }) => {
              setPage(1);
              setRowsPerPage(target.value);
            }}
          >
            {[50, 100, 150, 200, 250, 300].map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 3,
            alignItems: "center",
          }}
        >
          <Box className="lae-flex lae-flex-row">
            <Typography>
              Nº Registros: {meta && Object.keys(meta).length > 0 ? (isLastPage ? meta.total : meta.per_page * page) : 0} de{" "}
              {meta?.total ?? 0}
            </Typography>
          </Box>

          <Box className="lae-flex" sx={{
            gap: 0,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Button variant="text" style={{ minWidth: 0 }} onClick={handleBack} disabled={page === 1}>
              <Iconify
                icon="material-symbols:chevron-left"
                sx={{
                  color: page === 1 ? "gray" : "black",
                  height: 24,
                  width: 24,
                }}
              />
            </Button>
            <Typography>{meta && Object.keys(meta).length > 0 ? page : 0} / {meta?.last_page || 0}</Typography>
            <Button variant="text" style={{ minWidth: 0 }} onClick={handleNext} disabled={isLastPage}>
              <Iconify
                icon="material-symbols:chevron-right"
                sx={{
                  color: isLastPage ? "gray" : "black",
                  height: 24,
                  width: 24,
                }}
              />
            </Button>
            <style jsx>{`
          .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled{
            opacity: 0.5
          }
        `}</style>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ width: "100%", minHeight: "100vh" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <PaginationBar />
        <TableContainer>
          {children}
          {isLoading && <LinearProgress sx={{ width: "100%" }} />}
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              selected={selected}
              onSelectAllClick={handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              data={filteredData}
              isArrowDown={isArrowDown}
              // onStatusClick={handleStatusClick}
              headersData={headers}
              enableSelection={enableSection}
              enumerate={enumerate}
              onRequestSort={handleRequestSort}
              segundaCol={segundaCol}
              propCaducada={propCaducada}

            />

            <TableBody>
              {isLoading ? (
                <TableRow hover sx={{ cursor: "default" }}>
                  <TableCell colSpan={13} style={{ textAlign: "center" }}>
                    <CircularProgress style={{ color: "#92004D" }} />
                  </TableCell>
                </TableRow>
              ) : visibleRows?.length === 0 ? (
                <TableRow hover sx={{ cursor: "default" }}>
                  <TableCell colSpan={13} style={{ textAlign: "center" }}>
                    <span>{mensaje}</span>
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows?.length &&
                visibleRows.map((row, index) => {
                  return (
                    <TableRow
                      hover key={row.id}
                      sx={{ cursor: "default" }}
                      className={[row.caducada ? "caducada" : "", row.isWorkcenter ? index % 2 === 0 ? 'table-row-pair' : 'table-row-odd' : ""]}>
                      {enableSection && (
                        <TableCell padding="checkbox" colSpan={row.colSpan}>
                          <Checkbox
                            color="primary"
                            checked={row.userIdentifier ?? false}
                          /*  inputProps={{
                          "aria-labelledby": labelId,
                        }} */
                          />
                        </TableCell>
                      )}

                      {segundaCol && (
                        <TableCell style={{padding:0}}>
                          <p style={{
                            ...(row.esCreatedAt ? { width: '3px', height: '7vh', background: '#0767A2' } : {})
                          }}>
                          </p>
                        </TableCell>
                      )}
                      {propCaducada && (
                        <TableCell style={{padding:0}}>
                          <p style={{
                            ...(row.caducada ? { width: '3px', height: '5vh', background: '#EE4534' } : {}) 
                          }}>
                          </p>
                        </TableCell>
                      )}
                      {enumerate && (
                        <TableCell padding="checkbox" colSpan={row.colSpan}>
                          {(page - 1) * rowsPerPage + index + 1}
                        </TableCell>
                      )}
                      {headers?.map((header) => {

                        return (
                          <TableCell
                            align={header?.numeric ? "right" : "left"}
                            style={header?.style}
                            key={header.id}
                            onClick={(e) => {
                              if (typeof header.overrideFunc !== "function") {
                                e.stopPropagation();
                                if (clicable) {
                                  onClickRow(row);
                                }

                              }
                            }}
                          >
                            {typeof header?.overrideFunc === "function"
                              ? header?.overrideFunc(row[header.id], row)
                              : row[header.id] || ""}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationBar />
      </Paper>
    </Box>
  );
};

export default DataTable;
