import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom"; 
import { withRouter } from "../../../providers/withRouter";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { desvincularProveedor, desvincularProveedorDeFincas, formatoFechasEs } from "../../../functions/funciones";
import {

  Box,
  Typography,

} from "@mui/material";
import { getAaffListSuplier, getAllTecnicos, getFincasUnAAFF, getOneAAFF, getSuppliersListPropertie } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";

import { UsuarioContext } from "../../../providers/userContext";
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";
import Desvincular from "../../../icons/desvincular.svg";
import Warning from "../../../icons/warning.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';





const ProveedoresLista = (props) => {
  
  const [aaff, setAAFF] = useState([]);
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  
  const navigate = useNavigate();
  const [primeraCarga, setPrimeraCarga] = useState(true);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  const loadAAFF = async () => {
    const af = await getOneAAFF(props.params.nif)
    setAAFF(af)
  }

  useEffect(() => {
    if (!aaff.id) {
      loadAAFF();
    }
    if (aaff.id) {
      fetchData();
    }
  }, [recargar, aaff]);

  const user = useContext(UsuarioContext);
  
    const clearFilters = () => {
      
      if (user.rol === "CONSUL" && primeraCarga) {
        setBusqueda({
          ...busqueda,
          consul_id: user.id 
        });
        setPrimeraCarga(false);
      }
      else{
        setBusqueda({});
      }
      
      setClearFilterActivo(true);
    }

  const fetchData = async ({ offset, limit, filtros = busqueda,order,orderBy } = {}) => {
 
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy);

    if (!aaff.id) {
    return;
  }


    const payload = {
      filtros:{ ...filtros, aaff_id: aaff.id },
      // filtros:{...filtros, aaff_id: 3},
      params: { page: offset, limit, order,orderBy },
    };

    try {
      setLoading(true);
      const response = await getAaffListSuplier(payload);
      const { meta, data } = response;

      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handledesvincularCallback = () => {
setRecargar(!recargar);
  };

  const desvincularProvs = async (idProv) => {
    const fincas = await getFincasUnAAFF({aaff_id: aaff.id})
    const fincaIds = fincas.map(finca => finca.id);
    desvincularProveedorDeFincas(fincaIds, idProv, aaff.id).then((resultado) => {
      handledesvincularCallback(resultado)
    })
  }

  
  const formatData = (data) => {
    return data.map((row) => (
      // console.log(row),
      {
      nif: row.nif,
      createdAt: row.ultimaReclamacion === null ? row.createdAt : row.ultimaReclamacion,
      razonSocial: row.razonSocial,
      municipio: row.municipio,
      provincia: row.provincia,
      cumplimiento: row.porcentajeDocs,
      desvincular: <img
        src={Desvincular}
        alt="Desvincular"
        className="tableIcon"
        onClick={() => desvincularProvs(row.id)}
      ></img>,
      docsPendientes: row.hasPendingDocs
    }));
  };
  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    // fetchData({ offset: 1, limit: limite, filtros: { ...filtros, aaff_id: aaff.id } });
  }

  const proveedoresRows = [
    
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDOR",
      overrideFunc: (data, row) =>
      (
        <Link to={"/aaff/"+props.params.nif+"/proveedores/" + row.nif}>
          {data}
        </Link>
      )
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "ÚLTIMA RECLAMACIÓN",
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "cumplimiento",
      numeric: false,
      disablePadding: false,
      label: "CUMPLIMIENTO",
      noObjeto:true,
      overrideFunc: (row) => (
        <ProgressBarWithColors
          value={row}
          label={
            <Typography>
              {row}
            </Typography>
          }
        />
      ),
    },
    {
      id: "docsPendientes",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto:true,
      overrideFunc: (row) => (        
        <>
          {row > 0 ?
            <img src={Warning} width={15} alt="pendientes" />
            : null}
        </>
      ),
    },
    {
      id: "desvincular",
      numeric: false,
      disablePadding: false,
      label: "DESVINCULAR",
      noObjeto:true,
    },
  ]

  return (
    <>
      <BreadCrumb rutas={[{ path: '/aaff', label: 'Administrador' }, { path: `/aaff/${aaff.nif}/comunidades`, label: `${aaff.razonSocial}` }, { path: `/aaff/${aaff.nif}/proveedores`, label: 'Proveedores' }]} style={{ margin: '0 10px' }} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">LISTADO PROVEEDORES</div>
          <div className="tableContainer">
            <div style={{ display: "flex" }}>
              <FiltrosProveedores onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda}/>
              {/* <div className="exportar">
                <ExportarProveedoresLista busqueda={Resbusqueda} /> 
              </div> */}
            </div>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    clearFilterActivo={clearFilterActivo}
                    setClearFilterActivo={setClearFilterActivo}
                    mensaje={"No hay proveedores"}
                    enumerate={true}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProveedoresLista);
