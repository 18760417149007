import React, { useState, useEffect, useContext } from "react";
import DescargarNegro from "../../icons/descargar-negro.svg";
import { withRouter } from "../../providers/withRouter";
import {
  createDeclRes,
  getDeclResOfSupplier,
  getProveedor,
  getServicios,
  updateDeclRes,
  validateDeclRes,
} from "../../services";
import {
  abrirArchivo,
  subirDeclaracionResponsable,
  validarDeclRes,
} from "../../providers/documentos";
import { UsuarioContext } from "../../providers/userContext";
import { CircularProgress } from "@mui/material";
import { useSwal } from "../../utilities/showSwalFireAlert";
import TabledeclHelper from "./declResHelper";
import { useToastHook } from "../../utilities/showToast";

const DeclRes = (props) => {
  const user = useContext(UsuarioContext);
  const [aaff, setAAFF] = useState([]);
  const [recargar, setRecargar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const showAlert = useSwal();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const { showToast, updateToast, closeToast } = useToastHook();
  
  const fetchData = async () => {
    const res = await getDeclResOfSupplier(props.nifProv);
    setAAFF(res);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [!recargar, props.proveedor]);

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const handlePendingClick = async (row) => {
    try {

      if (user.rol != "SUPP") {
        abrirArchivo(row.declRes?.ruta);
        const ruta = row.declRes?.ruta;
        const id = row.declRes?.id;
        const nif = row.nif;

        const alertShow = await showAlert({
          title: "¿Qué quieres hacer con el documento?",
          text: "Si no se valida se borrará",
          confirmButtonText: "Validar",
          cancelButtonText: "Cancelar",
          denyButtonText: "No validar",
          confirmButtonColor: "#7bc977",
          cancelButtonColor: "rgb(130, 130, 130)",
          denyButtonColor: "#FF5252",
        });

        if (alertShow.isConfirmed) {
          const comprobar = await validateDeclRes(nif, id, { validado: true });

          if (comprobar) {
            showToast("Datos actualizado con éxito", { type: "success" });
            handleSubirDocsCallback(comprobar);
          }
        } else if (alertShow.isDenied) {
          const novalidar = await validateDeclRes(nif, id, { validado: false });

          if (novalidar) {
            handleSubirDocsCallback(novalidar);
          }
        }
      }
    } catch (error) {
      console.log(error);
      // showToast("Error al actualizar los datos", { type: "error" });
    }
  };

  const handleViewClick = (ruta) => {
    // console.log("Ver archivo:", ruta);
    abrirArchivo(ruta);
  };

  const handleAttachFile = async (row, file, inputRef) => {
    // console.log(row.id);
    // console.log(row);

    // console.log(row.declRes.id, file);
    const declResId = row.declRes.id;
    // console.log(declResId);

    // Actualiza el estado con el archivo subido
    setUploadedFiles((prev) => ({
      ...prev,
      [row.id]: file, // Asociar archivo a la fila
    }));

    // Restablecer el valor del input para permitir seleccionar el mismo archivo
    if (inputRef && inputRef.current) {
      inputRef.current.value = null;
    }

    if (declResId) {
      //actualizar
      try {
        const updatedata = await updateDeclRes(file, declResId);
        if (updatedata) {
          showToast("Datos cargados con éxito", { type: "success" });
          handleSubirDocsCallback(updatedata);
        }
      } catch (error) {
        console.log(error);
        showToast("Error al carga los datos", { type: "error" });
      }
    } else {
      try {
        //crear
        const create = await createDeclRes(file, props.nifProv, {
          aaff_id: row.id,
        });
        if (create) {
          showToast("Datos cargados con éxito", { type: "success" });
          handleSubirDocsCallback(create);
        }
      } catch (error) {
        console.log(error);
        showToast("Error al carga los datos", { type: "error" });
      }
    }
    // console.log(uploadedFiles);

    // const createDeclRes = await createDeclRes();
    // updateDeclRes(el.files[0], idDeclRes)
  };

  return (
    <>
      <div className="gridCard" style={{ position: "relative" }}>
        <div className="gridHead">DECLARACIONES RESPONSABLES</div>
        <div style={{ overflow: "scroll", maxHeight: 750 }}>
          {user.rol === "SUPP" ? (
            <div
              className="gridSubhead"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <a
                href="/files/Modelo-Declaracion-Responsable.pdf"
                download="Modelo-Declaracion-Responsable.pdf"
              >
                <span style={{ marginRight: 10 }}>Descargar modelo</span>{" "}
                <img
                  src={DescargarNegro}
                  width={12}
                  alt="Descargar declaración responsable"
                />
              </a>
            </div>
          ) : null}

          {isLoading ? (
            <CircularProgress
              style={{
                height: 40,
                width: 40,
                color: "#92004D",
                position: "absolute",
                top: "65%",
                left: "5%",
              }}
            />
          ) : aaff.length === 0 ? (
            <p
              style={{
                fontSize: "14px !important",
                textAlign: "center",
                padding: "20px",
              }}
            >
              No tiene declaraciones responsables
            </p>
          ) : (
            <table style={{ margin: 15 }}>
              <thead>
                <tr
                  style={{
                    fontSize: 11,
                    textAlign: "center",
                    margin: 0,
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: 400,
                    lineHeight: 1.5,
                    letterSpacing: "0.00938em",
                  }}
                >
                  <th></th>
                  <th></th>
                  <th style={{ margin: 5 }}>Ver</th>
                  <th>Adjuntar</th>
                </tr>
              </thead>
              <tbody>
                <TabledeclHelper
                  rows={aaff}
                  onPendingClick={handlePendingClick}
                  onViewClick={handleViewClick}
                  onAttachFile={handleAttachFile}
                  showAlert={showAlert}
                  user={user}
                />
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(DeclRes);
